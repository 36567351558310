import { WorkableInteraction, OutreachRecipientShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['WorkableInteraction', 'Workspace', 'Contact', 'USASpendingAward', 'Award']
});

type BulkUpdateWorkableInteractions = {
  params: { followed?: boolean; ignored?: boolean; ids?: string[]; notifyIds?: string[] };
  result: void;
};

type CreateWorkableInteraction = {
  params: {
    interactableType: string;
    interactableId: string;
    viewed?: boolean;
    followed?: boolean;
    ignored?: boolean;
    async?: boolean;
  };
  result: WorkableInteraction;
};

export type ShareWorkable = {
  params: {
    id: string;
    type: 'Contact' | 'USASpendingAward' | 'Award';
    subject: string;
    recipients: { name?: string; email: string; company?: string }[];
    customMessage?: string;
  };
  result: OutreachRecipientShow[];
};

export const workableInteractionsApi = api.injectEndpoints({
  endpoints: build => ({
    bulkUpdateWorkableInteractions: build.mutation<
      BulkUpdateWorkableInteractions['result'],
      BulkUpdateWorkableInteractions['params']
    >({
      query: body => ({
        url: `/v2/workable_interactions/bulk_update`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Workspace']
    }),

    createWorkableInteraction: build.mutation<CreateWorkableInteraction['result'], CreateWorkableInteraction['params']>(
      {
        query: body => ({
          url: `/v2/workable_interactions`,
          method: 'POST',
          body
        }),
        invalidatesTags: result => ['WorkableInteraction', ...(result ? [result.interactableType] : [])]
      }
    ),

    shareWorkable: build.mutation<ShareWorkable['result'], ShareWorkable['params']>({
      query: body => ({
        url: `/v2/workable_interactions/share`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, err, args) => ['WorkableInteraction', args.type]
    })
  })
});

export const {
  useBulkUpdateWorkableInteractionsMutation,
  useCreateWorkableInteractionMutation,
  useShareWorkableMutation
} = workableInteractionsApi;
