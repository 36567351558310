import React from 'react';
import { match } from 'ts-pattern';

import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { Main } from '@/app/atoms/Main/Main';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { GovernmentEntity } from '@/app/organisms/GovernmentEntity/GovernmentEntity';
import { DrawerType } from '@/app/organisms/AppDrawer/constants';
import { useAppDrawerSubNavTab } from '@/app/organisms/AppDrawer/useAppDrawerSubNavTab';
import { GovernmentEntityBudget } from '@/app/organisms/GovernmentEntity/GovernmentEntityBudget';

export const showGovernmentEntityDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  drawerType === 'entity' && drawerId;

export const GovernmentEntityDrawerBody = ({ id }: { id: string }) => {
  const tab = useAppDrawerSubNavTab('entity');
  const { isLoading } = useGetGovernmentEntityQuery({ id });

  if (isLoading) return <Loading type="flex-row" />;

  return (
    <Main className="max-w-full bg-gray-50">
      {match(tab)
        .with('Overview', () => (
          <MainColumn>
            <GovernmentEntity id={id} className="pb-20" />
          </MainColumn>
        ))
        // Add more tabs as needed:
        .with('Budgets', () => <BudgetsTab id={id} />)

        .otherwise(() => null)}
    </Main>
  );
};

const BudgetsTab = ({ id }: { id: string }) => {
  return (
    <MainColumn>
      <GovernmentEntityBudget id={id} className="pb-20" />
    </MainColumn>
  );
};
