import { P, match } from 'ts-pattern';

import {
  AwardSearch as APIAwardSearch,
  AwardIndex,
  SearchQueryMeta,
  TypeaheadItem,
  TypeaheadItemWithAliases,
  TypeaheadQuery,
  TypeaheadQueryWithAliases,
  AwardShow,
  AwardTransactionIndex,
  AwardSubAwardIndex
} from '@/types/__generated__/GovlyApi';
import { useSearchPagination } from '@/app/hooks/useSearchPagination';
import {
  AwardSearchFilters,
  RANGE_FIELDS,
  AGENCY_FIELDS,
  CompositeFilter
} from '@/app/hooks/search/useAwardSearchCacheV2';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['Award', 'AwardTransaction', 'AwardSubAward']
});

type GetAwards = {
  params: { uniqueKeys: string[]; searchType: 'fed' | 'sled' };
  results: AwardIndex[];
};

type GetAward = {
  params: { uniqueKey: string; searchType: 'fed' | 'sled' };
  result: AwardShow;
};

type GetAwardTransactions = {
  params: { uniqueKey: string; page?: number; searchType: 'fed' | 'sled' };
  result: {
    results: AwardTransactionIndex[];
    meta: {
      currentPage: number;
      per: number;
      total: number;
    };
  };
};

type GetAwardSubawards = {
  params: { uniqueKey: string; page?: number; searchType: 'fed' | 'sled' };
  result: {
    results: AwardSubAwardIndex[];
    meta: {
      currentPage: number;
      per: number;
      total: number;
    };
  };
};

type AwardSearch = {
  params: { query: string; page?: number; per?: number } & AwardSearchFilters;
  result: AwardSearch['response'] & { query: AwardSearch['params'] };
  response: {
    results: APIAwardSearch[];
    meta: SearchQueryMeta;
  };
};

type AwardTypeahead = {
  params: { query?: string; typeahead: string; filterValues?: Array<string>; view?: 'with_aliases' };
  result: TypeaheadQuery['results'] | TypeaheadQueryWithAliases['results'];
  response: TypeaheadQuery | TypeaheadQueryWithAliases;
};

type AwardExport = {
  params: { query: string } & AwardSearchFilters;
  result: void;
};

export const awardsApi = api.injectEndpoints({
  endpoints: build => ({
    getAwardsV2: build.query<GetAwards['results'], GetAwards['params']>({
      query: params => ({
        url: `/v2/awards`,
        params
      }),
      providesTags: res => res?.map(r => ({ type: 'Award', id: r.id })) ?? ['Award']
    }),

    getAwardV2: build.query<GetAward['result'], GetAward['params']>({
      query: ({ uniqueKey, searchType }) => ({
        url: `/v2/awards/${uniqueKey}`,
        params: { searchType }
      }),
      providesTags: (result, _err) => [{ type: 'Award', id: result?.id }]
    }),

    getAwardTransactionsV2: build.query<GetAwardTransactions['result'], GetAwardTransactions['params']>({
      query: ({ uniqueKey, ...params }) => ({
        url: `/v2/awards/${uniqueKey}/transactions`,
        params
      }),
      providesTags: result => [
        ...(result ? result.results.map(t => ({ type: 'AwardTransaction' as const, id: t.id })) : [])
      ]
    }),

    getAwardSubawardsV2: build.query<GetAwardSubawards['result'], GetAwardSubawards['params']>({
      query: ({ uniqueKey, ...params }) => ({
        url: `/v2/awards/${uniqueKey}/subawards`,
        params
      }),
      providesTags: result => [
        ...(result ? result.results.map(t => ({ type: 'AwardSubAward' as const, id: t.id })) : [])
      ]
    }),

    awardSearchV2: build.mutation<AwardSearch['result'], AwardSearch['params']>({
      query: body => ({
        url: '/v2/awards/search',
        method: 'POST',
        body: sanitizeFilters(body)
      }),
      transformResponse: (response: AwardSearch['response'], _meta, arg) => ({ query: arg, ...response })
    }),

    awardSearchQueryV2: build.query<AwardSearch['result'], AwardSearch['params']>({
      query: body => ({
        url: '/v2/awards/search',
        method: 'POST',
        body: sanitizeFilters(body)
      }),
      transformResponse: (response: AwardSearch['response'], _meta, arg) => ({ query: arg, ...response })
    }),

    awardTypeaheadV2: build.query<AwardTypeahead['result'], AwardTypeahead['params']>({
      query: params => ({
        url: `/v2/awards/typeahead`,
        params
      }),
      transformResponse: (response: AwardTypeahead['response'], _meta, _arg) => response.results || []
    }),

    awardExportV2: build.mutation<AwardExport['result'], AwardExport['params']>({
      query: body => ({
        url: `/v2/awards/export`,
        method: 'POST',
        body: sanitizeFilters(body)
      })
    })
  })
});

export const AWARD_SEARCH_CACHE_KEY = 'award-search-v2';

export const useAwardSearchPagination = () =>
  useSearchPagination({
    mutation: useAwardSearchMutation,
    cacheKey: AWARD_SEARCH_CACHE_KEY
  });

export const AGENCY_DEPTH_PATTERN = /(:\d+)+$/;

function sanitizeFilters(filters: AwardSearchFilters) {
  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => {
      if (RANGE_FIELDS.includes(key as (typeof RANGE_FIELDS)[number]) && Array.isArray(value)) {
        const [min, max] = value;
        // undefined/null gets stripped by rails params, so we preserve the tuple structure with an empty string along with #presence in filter_context.rb
        return match({ min, max })
          .with({ min: P.nullish, max: P.nullish }, () => [key, []])
          .with({ min: P.nullish, max: P.number }, ({ max }) => [key, ['', max]])
          .with({ min: P.number, max: P.nullish }, ({ min }) => [key, [min, '']])
          .otherwise(({ min, max }) => [key, [min, max]]);
      }

      if (AGENCY_FIELDS.includes(key as (typeof AGENCY_FIELDS)[number])) {
        const filter = value as CompositeFilter;
        const withDepthRemoved = Object.fromEntries(
          Object.entries(filter).map(([k, v]) => {
            return [k, v.map(s => AgencyDepthUtils.parseValueLabel(s))];
          })
        );

        return [key, withDepthRemoved];
      }

      return [key, value];
    })
  );
}

export const AgencyDepthUtils = {
  getItemValue: (item: TypeaheadItem | TypeaheadItemWithAliases) => `${item.value}:${item.depth ?? 0}`,
  parseValueLabel: (value: string) => value.replace(AGENCY_DEPTH_PATTERN, '')
};

export const {
  useAwardSearchV2Mutation: useAwardSearchMutation,
  useGetAwardV2Query: useGetAwardQuery,
  useGetAwardsV2Query: useGetAwardsQuery,
  useAwardTypeaheadV2Query: useAwardTypeaheadQuery,
  useAwardSearchQueryV2Query: useAwardSearchQueryQuery,
  useAwardExportV2Mutation: useAwardExportMutation,
  useGetAwardTransactionsV2Query: useGetAwardTransactionsQuery,
  useGetAwardSubawardsV2Query: useGetAwardSubawardsQuery
} = awardsApi;
