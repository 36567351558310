import React from 'react';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';

export const Summary = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity?.description) return null;

  return (
    <Card title={<span>Summary</span>} titleRenderer={CardHeadingSmall} collapsible={true}>
      <CardBody data-test="government-entity-description">{governmentEntity.description}</CardBody>
    </Card>
  );
};
