import React from 'react';
import { Option } from 'space-monad';
import { Button } from '@blueprintjs/core';

import { useCollaborateOppTeamingBoardPostMutation } from '@/api/oppTeamingBoardPostsApi';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { ACTIVITY_LOG, formatTime } from '@/app/lib/dates';
import { LogoOrName } from '@/app/molecules/LogoOrName/LogoOrName';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { toast } from '@/app/lib/toaster';
import { useAuthorized } from '@/app/hooks/useAuthorize';
import {
  FollowWorklistSearch,
  TeamingBoardPostFeed,
  WorkspaceOppTeamingBoardPost
} from '@/types/__generated__/GovlyApi';
import { OppWorkspacePreview } from '@/app/organisms/OppWorkspacePreview/OppWorkspacePreview';
import { useOpenWorkspace } from '@/app/organisms/OppSearchWorkspaces/useOpenWorkspace';
import { OppWorkspacePreviewFollow } from '@/app/organisms/OppWorkspacePreview/types';

export type OppTeamingBoardFeedCardProps = {
  oppId: string;
  post: TeamingBoardPostFeed;
  onDelete: (postId: string) => void;
  workspaces: WorkspaceOppTeamingBoardPost[];
};

export const OppTeamingBoardFeedCard = ({ oppId, post, onDelete, workspaces }: OppTeamingBoardFeedCardProps) => {
  const currentOrgId = useCurrentUserAttribute('organizationId');
  const currentOrgUserId = useCurrentUserAttribute('id');
  const isAdmin = useAuthorized({ role: 'admin' });

  const workspacesByFollowsOrgId = workspaces.reduce(
    (acc, workspace) => {
      workspace.follows.forEach(f => {
        if (!acc[f.organizationUserId]) {
          acc[f.organizationUserId] = [];
        }
        acc[f.organizationUserId].push(workspace);
      });
      return acc;
    },
    {} as Record<string, WorkspaceOppTeamingBoardPost[]>
  );

  const postWorkspaces = workspacesByFollowsOrgId[post.createdBy?.id ?? ''] ?? [];

  const [collaborate, { isLoading: isCollaborateLoading }] = useCollaborateOppTeamingBoardPostMutation();

  const handleCollaborate = async (postId: string) => {
    const workspace = await collaborate({ postId, oppId }).unwrap();
    toast({
      message: 'Workspace created',
      intent: 'success',
      action: {
        text: 'Go to workspace',
        target: '_blank',
        href: workspace.link
      }
    });
  };
  const canDelete = post.organization.id === currentOrgId && (isAdmin || post.createdBy?.id === currentOrgUserId);

  const openWorkspace = useOpenWorkspace({ oppId });
  const isCrossOrg = post.organization.id !== currentOrgId;

  return (
    <Card
      key={post.id}
      title={
        <LogoOrName
          imgProps={{ className: 'max-h-12' }}
          both
          logo={post.organization.logo}
          name={post.organization.name ?? ''}
        />
      }
      rightElement={
        isCrossOrg ? (
          <Button
            intent="primary"
            icon="chat"
            text={`Collaborate with ${post.organization.name}`}
            loading={isCollaborateLoading}
            onClick={() => handleCollaborate(post.id)}
          />
        ) : undefined
      }
    >
      <CardBody className="space-y-4">
        <div className="flex justify-between">
          {Option(post.createdBy)
            .map(({ initials, avatar, avatarColor, name, email, organizationTeams }) => (
              <AvatarWithName
                key={email}
                initials={initials}
                imgSrc={avatar?.thumbUrl}
                avatarColor={avatarColor}
                name={name}
                email={email}
                teams={organizationTeams}
                subtext={formatTime(post.createdAt, ACTIVITY_LOG)}
              />
            ))
            .get()}

          {canDelete && (
            <div>
              <Button minimal aria-label="Delete post" icon="cross" intent="danger" onClick={() => onDelete(post.id)} />
            </div>
          )}
        </div>

        <div>
          <div className="font-bold">
            <span>{post.subject}</span>
          </div>

          <div className="prose prose-sm">
            <div dangerouslySetInnerHTML={{ __html: post.message ?? '' }} />
          </div>
        </div>

        {postWorkspaces.map(workspace => {
          const { id: workspaceId, follows = [] } = workspace;
          const displayableFollows = follows.filter(({ state }) => state !== 'unfollowed').map(toPreviewFollow);

          return (
            <Card key={workspaceId}>
              <CardBody className="flex space-y-0 flex-wrap gap-y-2 justify-between items-center">
                <OppWorkspacePreview
                  workspace={workspace}
                  onClick={(e: React.MouseEvent<HTMLElement>) => openWorkspace(e, workspaceId)}
                  displayableFollows={displayableFollows}
                  headerClass="flex flex-col gap-y-2 items-start"
                  followsClass="flex flex-col gap-y-2 items-start sm:items-end mt-2"
                  oppWorkspaceHeaderProps={{
                    className: 'w-full',
                    newTabTooltipTitle: 'View existing Workspace'
                  }}
                />
              </CardBody>
            </Card>
          );
        })}
      </CardBody>
    </Card>
  );
};

const toPreviewFollow = (follow: FollowWorklistSearch): OppWorkspacePreviewFollow => {
  return {
    ...follow,
    organizationUser: {
      id: follow.organizationUserId,
      name: follow.organizationUserName,
      organizationName: follow.organizationUserOrganizationName,
      initials: follow.organizationUserInitials,
      avatarColor: follow.organizationUserAvatarColor,
      ...(follow.organizationUserAvatarThumbUrl && {
        avatar: {
          thumbUrl: follow.organizationUserAvatarThumbUrl ?? ''
        }
      })
    }
  };
};
