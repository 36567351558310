import { Feed, FeedShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Feed'] });

function isFeedShow(feeds?: Feed[] | FeedShow[]): feeds is FeedShow[] {
  return feeds?.[0]?.__typename === 'FeedShow';
}

type GetFeeds = {
  params: {
    filter?: 'all' | 'public_searchable' | 'searchable' | 'public';
    view?: 'show';
    jurisdiction?: 'fed' | 'sled';
  } | void;
  result: Feed[] | FeedShow[];
};

type GetFeed = {
  params: { id: string };
  result: FeedShow;
};

export const FeedsApi = api.injectEndpoints({
  endpoints: build => ({
    getFeeds: build.query<GetFeeds['result'], GetFeeds['params']>({
      query: params => ({ url: '/v2/feeds', params: params ? params : undefined }),
      providesTags: result => [
        'Feed',
        ...(isFeedShow(result) ? result.map(cv => ({ type: 'Feed' as const, id: cv.id })) : [])
      ]
    }),

    getFeed: build.query<GetFeed['result'], GetFeed['params']>({
      query: ({ id }) => ({ url: `/v2/feeds/${id}` }),
      providesTags: ['Feed']
    })
  })
});

export const { useGetFeedsQuery, useGetFeedQuery } = FeedsApi;
