import React, { useState } from 'react';
import { Alert, NonIdealState, Switch } from '@blueprintjs/core';
import { match } from 'ts-pattern';
import { groupBy } from 'lodash-es';

import {
  useDeleteOppTeamingBoardPostMutation,
  useGetOppTeamingBoardPostsQuery,
  useGetOppTeamingBoardPostsWorkspacesQuery
} from '@/api/oppTeamingBoardPostsApi';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { successToast } from '@/app/lib/toaster';
import { OppTeamingBoardFeedCard } from '@/app/organisms/OppTeamingBoardFeed/OppTeamingBoardFeedCard';
import { CreateTeamingBoardPostButton } from '@/app/organisms/CreateTeamingBoardPostButton/CreateTeamingBoardPostButton';
import { useGetOppQuery } from '@/api/oppsApi';

export type OppTeamingBoardFeedProps = {
  oppId: string;
};

export const OppTeamingBoardFeed = ({ oppId }: OppTeamingBoardFeedProps) => {
  const currentOrgId = useCurrentUserAttribute('organizationId');

  const oppQuery = useGetOppQuery({ id: oppId });
  const { data = [], isLoading } = useGetOppTeamingBoardPostsQuery({ oppId, view: 'feed' });

  const [sameOrgIsHidden, setSameOrgIsHidden] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState<string | null>(null);

  const [deletePost, { isLoading: isDeleting }] = useDeleteOppTeamingBoardPostMutation();

  const filteredData = sameOrgIsHidden ? data.filter(post => post.organization.id !== currentOrgId) : data;

  const workspacesQuery = useGetOppTeamingBoardPostsWorkspacesQuery(
    { oppId },
    {
      selectFromResult: result => ({
        ...result,
        postsByOppId: groupBy(result.data?.results ?? [], w => w.workableId)
      })
    }
  );

  return (
    <div className="flex flex-col gap-4 overflow-y-auto p-px">
      <div className="flex justify-end -mt-2 -mb-4 pt-2">
        <Switch
          checked={sameOrgIsHidden}
          onChange={() => setSameOrgIsHidden(!sameOrgIsHidden)}
          label="Hide posts from my organization"
        />
      </div>
      {match({ data: filteredData, isLoading })
        .with({ isLoading: true }, () => <Loading type="card-list" />)
        .with({ data: [] }, () => (
          <Card>
            <CardBody>
              <NonIdealState
                title="No one has expressed teaming interest yet"
                icon="feed"
                action={
                  <CreateTeamingBoardPostButton
                    oppId={oppId}
                    oppDisplayName={oppQuery.data?.displayName ?? ''}
                    buttonProps={{ icon: undefined }}
                  />
                }
              />
            </CardBody>
          </Card>
        ))
        .otherwise(({ data }) =>
          data.map(post => (
            <OppTeamingBoardFeedCard
              key={post.id}
              oppId={oppId}
              post={post}
              onDelete={() => setPostIdToDelete(post.id)}
              workspaces={workspacesQuery.postsByOppId[post.oppId ?? ''] ?? []}
            />
          ))
        )}

      <Alert
        isOpen={!!postIdToDelete}
        onClose={() => setPostIdToDelete(null)}
        confirmButtonText="Delete"
        loading={isDeleting}
        cancelButtonText="Cancel"
        intent="danger"
        onConfirm={async () => {
          if (!postIdToDelete) return;
          await deletePost({ oppId, postId: postIdToDelete });
          setPostIdToDelete(null);
          successToast('Post deleted');
        }}
      >
        Are you sure you want to delete this post?
      </Alert>
    </div>
  );
};
