import { groupBy, keyBy } from 'lodash-es';

import { OrganizationUserCurrentOrg, Partnership } from '@/types/__generated__/GovlyApi';
import { useGetPartnershipsQuery } from '@/api/partnershipsApi';
import { useGetDistributorProfilesQuery } from '@/api/distributorProfilesApi';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { LimitedFollow } from '@/api/workspacesApi';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';
import { useGetOrganizationUsersQuery } from '@/api/organizationUsersApi';

import { followToOrgUserRecipient, sortRecipients } from './utils';
import type { Recipient } from './utils';

export const useInitialRecipients = ({ follows }: { follows: LimitedFollow[] }) => {
  const newFormEnabled = useFeatureFlag('outreach-form');
  const { orgUsersById } = useGetOrganizationUsersQuery(
    { view: 'current_org' },
    {
      selectFromResult: result => ({
        ...result,
        orgUsersById: keyBy((result.data as OrganizationUserCurrentOrg[]) ?? [], o => o.id)
      })
    }
  );
  const { orgUsersById: partnerUsersById } = useGetOrganizationUsersQuery(
    { view: 'current_org', filter: 'partners' },
    {
      selectFromResult: result => ({
        ...result,
        orgUsersById: keyBy((result.data as OrganizationUserCurrentOrg[]) ?? [], o => o.id)
      })
    }
  );

  const currentOrgId = useCurrentUserAttribute('organizationId');
  const distributorsQuery = useGetDistributorProfilesQuery(undefined, {
    selectFromResult: result => ({ ...result, lookup: keyBy(result.data ?? [], d => d.organizationId) }),
    skip: !newFormEnabled
  });
  const partnersQuery = useGetPartnershipsQuery(undefined, {
    selectFromResult: result => ({ ...result, lookup: keyBy((result.data ?? []) as Partnership[], p => p.partnerId) }),
    skip: !newFormEnabled
  });

  if (!newFormEnabled) return [];

  const groupedByOrg = groupBy(
    follows
      .filter(f => f.state === 'following')
      .map(f => ({
        ...f,
        organizationUser:
          orgUsersById[f.organizationUserId] ?? partnerUsersById[f.organizationUserId] ?? f.organizationUser
      })),
    f => f.organizationUser?.organizationId
  );

  const recipients = Object.entries(groupedByOrg).reduce((acc, [orgId, followers]) => {
    const isTeam = orgId === currentOrgId;
    if (isTeam) {
      return acc.concat(followers.map(followToOrgUserRecipient));
    }

    const distributor = distributorsQuery.lookup[orgId];
    if (distributor) {
      return acc.concat([
        {
          type: 'distributor',
          organizationId: orgId,
          name: distributor.organizationName ?? '(missing distributor name)',
          recipients: followers.map(followToOrgUserRecipient)
        }
      ]);
    }

    const partner = partnersQuery.lookup[orgId];
    if (partner) {
      return acc.concat([
        {
          type: 'partner',
          organizationId: orgId,
          name: partner.partnerName ?? '(missing partner name)',
          recipients: followers.map(followToOrgUserRecipient)
        }
      ]);
    }
    return acc;
  }, [] as Recipient[]);

  return sortRecipients(recipients);
};
