import { PartnerFeed } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['PartnerFeed', 'Partnership', 'ProviderFeed']
});

export type GetPartnerFeeds = {
  params: { partnerId?: string } | void;
  result: PartnerFeed[];
};

type BulkCreatePartnerFeeds = {
  params: {
    partnerId?: string;
    partnerFeeds: {
      providerFeedPublicId: string;
      organizationId: string;
      partnershipId?: string;
    }[];
  };
  result: PartnerFeed[];
};

type CreatePartnerFeed = {
  params: {
    providerFeedPublicId: string;
    organizationId: string;
    partnershipId?: string;
  };
  result: PartnerFeed;
};

type DeletePartnerFeed = {
  params: { id: string; providerFeedId?: string };
  result: void;
};

export const partnerFeedsApi = api.injectEndpoints({
  endpoints: build => ({
    getPartnerFeeds: build.query<GetPartnerFeeds['result'], GetPartnerFeeds['params']>({
      query: params => ({ url: '/v2/partner_feeds', params: typeof params === 'object' ? params : undefined }),
      providesTags: (result, err, params = {}) => [
        {
          type: 'PartnerFeed',
          id: typeof params === 'object' ? `PARTNER:${params.partnerId}` : undefined
        },
        'PartnerFeed'
      ]
    }),

    bulkCreatePartnerFeeds: build.mutation<BulkCreatePartnerFeeds['result'], BulkCreatePartnerFeeds['params']>({
      query: ({ partnerFeeds }) => ({
        url: '/v2/partner_feeds/bulk_create',
        method: 'POST',
        body: { partnerFeeds }
      }),
      invalidatesTags: (_result, _error, { partnerId }) => [{ type: 'PartnerFeed', id: `PARTNER:${partnerId}` }]
    }),

    createPartnerFeed: build.mutation<CreatePartnerFeed['result'], CreatePartnerFeed['params']>({
      query: body => ({
        url: '/v2/partner_feeds',
        method: 'POST',
        body
      }),
      invalidatesTags: result => [
        'Partnership',
        'PartnerFeed',
        ...(result ? [{ type: 'ProviderFeed' as const, id: result.providerFeedId }] : [])
      ]
    }),

    deletePartnerFeed: build.mutation<DeletePartnerFeed['result'], DeletePartnerFeed['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/partner_feeds/${id}`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: (_result, _error, { providerFeedId }) => [
        'Partnership',
        { type: 'ProviderFeed', id: providerFeedId },
        'PartnerFeed'
      ]
    })
  })
});

export const {
  useGetPartnerFeedsQuery,
  useCreatePartnerFeedMutation,
  useBulkCreatePartnerFeedsMutation,
  useDeletePartnerFeedMutation
} = partnerFeedsApi;
