import React, { useEffect } from 'react';
import { Button, Icon, NonIdealState } from '@blueprintjs/core';
import { match } from 'ts-pattern';
import { FieldArray } from 'formik';

import { RecipientSelectTeamSelect } from '@/app/organisms/RecipientSelect/RecipientSelectTeamSelect';
import { RecipientSelectPartnerSelect } from '@/app/organisms/RecipientSelect/RecipientSelectPartnerSelect';
import { RecipientSelectDistributorSelect } from '@/app/organisms/RecipientSelect/RecipientSelectDistributorSelect';
import { useRecipientSelect } from '@/app/organisms/RecipientSelect/utils';
import { RecipientSelectPartnerItem } from '@/app/organisms/RecipientSelect/RecipientSelectPartnerItem';
import { RecipientSelectDistributorItem } from '@/app/organisms/RecipientSelect/RecipientSelectDistributorItem';
import { RecipientSelectGuestItem } from '@/app/organisms/RecipientSelect/RecipientSelectGuestItem';
import { RecipientSelectTeamItem } from '@/app/organisms/RecipientSelect/RecipientSelectTeamItem';
import { cn } from '@/app/lib/cn';
import {
  RecipientSelectProvider,
  useRecipientSelectContext
} from '@/app/organisms/RecipientSelect/RecipientSelectProvider';

export type RecipientSelectProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  context: 'follow' | 'share';
  minRecipients?: number;
};

const WithContext = ({ context, ...props }: RecipientSelectProps) => {
  return (
    <RecipientSelectProvider context={context}>
      <RecipientSelect {...props} />
    </RecipientSelectProvider>
  );
};

export { WithContext as RecipientSelect };

const RecipientSelect = ({ minRecipients, ...props }: Omit<RecipientSelectProps, 'context'>) => {
  const { values, validateField, errors, touched } = useRecipientSelect();
  const { context } = useRecipientSelectContext();

  useEffect(() => {
    // don't need to validate after we've passed the min
    if (minRecipients != null && values.recipients.length <= minRecipients) {
      validateField('recipients');
    }
  }, [values.recipients.length, minRecipients, validateField]);

  return (
    <div {...props} className={cn('flex flex-col gap-2 overflow-y-auto max-h-[640px] p-px', props.className)}>
      <FieldArray name="recipients">
        {helpers => (
          <div className="space-y-4">
            <div className="flex flex-wrap gap-2 justify-center">
              <RecipientSelectTeamSelect helpers={helpers} />
              <RecipientSelectPartnerSelect helpers={helpers} />
              <RecipientSelectDistributorSelect helpers={helpers} />
              {context === 'share' ? (
                <Button
                  small
                  minimal
                  icon={<Icon icon="person" className="text-orange-500" />}
                  disabled={values.recipients.some(recipient => recipient.type === 'guest')}
                  text="Guest"
                  rightIcon="plus"
                  onClick={() => helpers.push({ type: 'guest', recipients: [] })}
                />
              ) : null}
            </div>

            <div className={cn('flex flex-col gap-2 px-px')}>
              {values.recipients.length === 0 ? (
                <NonIdealState
                  icon="person"
                  {...(context === 'share'
                    ? {
                        title: 'No recipients',
                        description: 'Add a teammate, partner, distributor, or guest to get started'
                      }
                    : {
                        title: 'No followers'
                      })}
                />
              ) : (
                values.recipients.map((recipient, index) =>
                  match(recipient)
                    .with({ type: 'organizationUser' }, () => (
                      <RecipientSelectTeamItem key={index} index={index} helpers={helpers} />
                    ))
                    .with({ type: 'partner' }, ({ organizationId }) => (
                      <RecipientSelectPartnerItem
                        key={index}
                        organizationId={organizationId}
                        index={index}
                        helpers={helpers}
                      />
                    ))
                    .with({ type: 'distributor' }, ({ organizationId }) => (
                      <RecipientSelectDistributorItem
                        helpers={helpers}
                        key={index}
                        organizationId={organizationId}
                        index={index}
                      />
                    ))
                    .with({ type: 'guest' }, () => (
                      <RecipientSelectGuestItem key={index} index={index} helpers={helpers} />
                    ))
                    .exhaustive()
                )
              )}
            </div>
          </div>
        )}
      </FieldArray>
      {errors.recipients && touched.recipients ? (
        <div className="flex justify-center">
          <span className="text-red-500">{errors.recipients.toString()}</span>
        </div>
      ) : null}
    </div>
  );
};
