import { OutreachRecipientShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OppSource'] });

type ShareOppSource = {
  params: {
    id: string;
    subject: string;
    recipients: {
      name?: string;
      email: string;
      company?: string;
    }[];
    customMessage?: string;
  };
  result: OutreachRecipientShow[];
};
export const oppSourcesApi = api.injectEndpoints({
  endpoints: build => ({
    shareOppSource: build.mutation<ShareOppSource['result'], ShareOppSource['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/opp_sources/${id}/share`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useShareOppSourceMutation } = oppSourcesApi;
