import { JSONObject, OppSearchDisplayable } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OppSearch'] });

type GetOppSearches = {
  params: {
    active?: boolean;
  } | void;
  result: OppSearchDisplayable[];
};

type GetOppSearch = {
  params: {
    id: string;
  };
  result: OppSearchDisplayable;
};

type CreateOppSearch = {
  params: JSONObject;
  result: OppSearchDisplayable;
};

type UpdateOppSearch = {
  params: {
    id: string;
  } & JSONObject;
  result: OppSearchDisplayable;
};

export const oppSearchesApi = api.injectEndpoints({
  endpoints: build => ({
    getOppSearches: build.query<GetOppSearches['result'], GetOppSearches['params']>({
      query: params => {
        return { url: '/v2/opp_searches', params: params || {} };
      },
      providesTags: result =>
        Array.isArray(result)
          ? [...result.map(({ id }) => ({ type: 'OppSearch' as const, id })), 'OppSearch']
          : ['OppSearch']
    }),

    getOppSearch: build.query<GetOppSearch['result'], GetOppSearch['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/opp_searches/${id}`, params }),
      providesTags: result => [{ type: 'OppSearch', id: result?.id }],
      keepUnusedDataFor: 180
    }),

    createOppSearch: build.mutation<CreateOppSearch['result'], CreateOppSearch['params']>({
      query: body => ({
        url: `/v2/opp_searches`,
        method: 'POST',
        body
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: createdOppSearch } = await queryFulfilled;
          dispatch(
            oppSearchesApi.util.updateQueryData('getOppSearches', { active: true }, draftOppSearches => {
              draftOppSearches.push(createdOppSearch);
            })
          );
        } catch {}
      }
    }),

    updateOppSearch: build.mutation<UpdateOppSearch['result'], UpdateOppSearch['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/opp_searches/${id}`,
        method: 'PATCH',
        body
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedOppSearch } = await queryFulfilled;

          dispatch(
            oppSearchesApi.util.updateQueryData('getOppSearches', { active: true }, draftOppSearches =>
              draftOppSearches.map(oppSearch => (oppSearch.id === id ? updatedOppSearch : oppSearch))
            )
          );
          dispatch(
            oppSearchesApi.util.updateQueryData('getOppSearches', {}, draftOppSearches =>
              draftOppSearches.map(oppSearch => (oppSearch.id === id ? updatedOppSearch : oppSearch))
            )
          );
        } catch {}
      }
    })
  })
});

export const { useGetOppSearchesQuery, useCreateOppSearchMutation, useUpdateOppSearchMutation, useGetOppSearchQuery } =
  oppSearchesApi;
