import { Comment } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Workspace', 'Comment', 'WorkspaceAttachment'] });

const invalidatesTags = <Arg extends { oppId?: string; commentableId: string }>(
  result: Comment | undefined,
  _err: unknown,
  arg: Arg
) => [
  { type: 'Workspace' as const, id: arg.commentableId },
  { type: 'WorkspaceAttachment' as const, workspaceId: arg.commentableId },
  { type: 'Workspace' as const, id: `OPP:${arg.oppId}` },
  ...(result ? [{ type: 'Comment' as const, id: result.id }] : [])
];

type CreateOppWorkspaceComment = {
  params: {
    oppId: string;
    commentableId: string;
    content: string;
    notifyIds: string[];
    attachmentSignedIds: string[];
  };
  result: Comment;
};

type UpdateOppWorkspaceComment = {
  params: {
    id: string;
    content: string;
    oppId: string;
    commentableId: string;
    attachmentSignedIds: string[];
  };
  result: Comment;
};

type DeleteOppWorkspaceComment = {
  params: { id: string };
  result: void;
};

export const oppWorkspaceCommentsApi = api.injectEndpoints({
  endpoints: build => ({
    createOppWorkspaceComment: build.mutation<CreateOppWorkspaceComment['result'], CreateOppWorkspaceComment['params']>(
      {
        query: body => ({
          url: `/v2/opp_workspace_comments`,
          method: 'POST',
          body
        }),
        invalidatesTags
      }
    ),

    updateOppWorkspaceComment: build.mutation<UpdateOppWorkspaceComment['result'], UpdateOppWorkspaceComment['params']>(
      {
        query: ({ id, ...body }) => ({
          url: `/v2/opp_workspace_comments/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags
      }
    ),

    deleteOppWorkspaceComment: build.mutation<DeleteOppWorkspaceComment['result'], DeleteOppWorkspaceComment['params']>(
      {
        query: ({ id, ...body }) => ({
          url: `/v2/opp_workspace_comments/${id}`,
          method: 'DELETE',
          body
        }),
        invalidatesTags: ['Workspace']
      }
    )
  })
});

export const {
  useCreateOppWorkspaceCommentMutation,
  useUpdateOppWorkspaceCommentMutation,
  useDeleteOppWorkspaceCommentMutation
} = oppWorkspaceCommentsApi;
