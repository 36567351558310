import { GovernmentEntityTypeaheadOpps } from '@/types/__generated__/GovlyApi';

// internal for creation
// picks are so that we don't have to deal with partials on temporary insertions
type GovernmentEntityTypeaheadHierarchy = GovernmentEntityTypeaheadOpps & {
  children: GovernmentEntityTypeaheadHierarchy[];
};
type GovernmentEntityTypeaheadHierarchyInfo = Pick<GovernmentEntityTypeaheadHierarchy, 'id' | 'children'>;

// simple binary search for id sorting
function findSortedInsertIndex(list: GovernmentEntityTypeaheadHierarchyInfo[], id: string) {
  let left = 0;
  let right = list.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const comp = parseInt(id) - parseInt(list[mid].id);

    if (comp === 0) return mid;
    if (comp > 0) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return left;
}

/**
 * Performs a topological sort on government entities while maintaining a secondary sort by ID.
 *
 * The resulting array is sorted with two priorities:
 * 1. Parent entities appear before their children
 * 2. When hierarchical relationship is equal, sorts by entity ID
 *
 * @param entities - Array of government entity typeahead options to be sorted
 * @returns Sorted array of government entities
 */
export function topoSortGovernmentEntities(entities: GovernmentEntityTypeaheadOpps[]) {
  // build a tree, then flatten it
  const tree: GovernmentEntityTypeaheadHierarchyInfo[] = [];
  entities.forEach(entity => {
    let cursor = tree;
    const hierarchy = Array.from(entity.hierarchyIds || []);

    // remove us from the hierarchy
    hierarchy.pop();

    // for each level in the hierarchy, ensure the path exists
    for (const id of hierarchy) {
      const index = findSortedInsertIndex(cursor, id);
      let extant = cursor[index];

      // not present yet
      if (extant == null) {
        extant = { id, children: [] };
        cursor.splice(index, 0, extant);
      }

      cursor = extant.children;
    }

    // find where we go
    const index = findSortedInsertIndex(cursor, entity.id);
    const extant = cursor[index];

    // not in the tree (empty or not a match at the index)
    if (extant == null || extant.id !== entity.id) {
      cursor.splice(index, 0, { ...entity, children: [] });
    }

    // already in the tree as a temporary
    else {
      cursor.splice(index, 1, { ...entity, ...extant });
    }
  });

  // this is normal entities, but topologically sorted (parent before children, id order)
  const dftraverse = (node: GovernmentEntityTypeaheadHierarchy): GovernmentEntityTypeaheadOpps[] => [
    node,
    ...node.children.flatMap(dftraverse)
  ];

  // we filled in all the trees, so this is a valid cast
  const values = (tree as GovernmentEntityTypeaheadHierarchy[]).flatMap(dftraverse);

  // this is a downcast, so consumers can't get at the children arrays
  return values as GovernmentEntityTypeaheadOpps[];
}
