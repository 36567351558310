import React, { useState } from 'react';
import { Button, ButtonProps, Callout, Dialog, EntityTitle, Section, SectionCard } from '@blueprintjs/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { TextAreaInput } from '@/app/atoms/inputs/TextAreaInput/TextAreaInput';
import { Editor } from '@/app/molecules/Editor/Editor';
import { useCreateOppTeamingBoardPostMutation, useGetOppTeamingBoardPostsQuery } from '@/api/oppTeamingBoardPostsApi';
import { successToast } from '@/app/lib/toaster';
import { Card, CardBody, CardFooter } from '@/app/atoms/Card/Card';
import { useCurrentUserAttribute } from '@/api/currentUserApi';

export type CreateTeamingBoardPostButtonProps = {
  oppId: string;
  oppDisplayName: string;
  buttonProps?: Partial<ButtonProps>;
};

const validationSchema = yup.object({
  subject: yup.string().required('Subject is required'),
  message: yup.string().required('Message is required')
});

export const CreateTeamingBoardPostButton = ({
  oppId,
  oppDisplayName,
  buttonProps
}: CreateTeamingBoardPostButtonProps) => {
  const currentOrgId = useCurrentUserAttribute('organizationId');
  const { data: teamingBoardPosts } = useGetOppTeamingBoardPostsQuery({ oppId, view: 'index' });
  const orgPosts = teamingBoardPosts?.filter(post => post.organizationId === currentOrgId) ?? [];
  const [createTeamingBoardPost] = useCreateOppTeamingBoardPostMutation();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <div>
      <Button icon="feed" intent="primary" onClick={() => setDialogIsOpen(true)} fill {...buttonProps}>
        I&apos;m interested in teaming
      </Button>

      <Dialog onClose={() => setDialogIsOpen(false)} isOpen={dialogIsOpen} title="Teaming Board Post">
        <Formik
          initialValues={{
            subject: `We're interested in teaming up on ${oppDisplayName}!`,
            message: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async values => {
            await createTeamingBoardPost({ oppId, ...values });
            successToast('Posted to Teaming Board');
            setDialogIsOpen(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <Card>
                <CardBody className="space-y-4">
                  <div className="space-y-4">
                    <TextAreaInput name="subject" label="Subject" />
                    <Editor
                      id="message"
                      onChange={({ target: { value: newValue } }) => setFieldValue('message', newValue)}
                      error={Boolean(errors.message)}
                      inputProps={{ value: values.message }}
                      trixEditorProps={{ placeholder: 'We specialize in...' }}
                      formGroupProps={{
                        label: 'Message',
                        helperText: <span className="text-red-500">{errors.message}</span>
                      }}
                    />
                  </div>

                  {orgPosts.length > 0 ? (
                    <Section collapsible collapseProps={{ defaultIsOpen: false }} title="Previous Posts">
                      <SectionCard className="max-h-[200px] overflow-auto space-y-2 [&>*:nth-child(even)]:bg-gray-100">
                        {orgPosts.map(post => (
                          <EntityTitle
                            className="p-1 rounded"
                            key={post.id}
                            title={`Subject: ${post.subject}`}
                            subtitle={`${post.createdByEmail} on ${new Date(post.createdAt).toLocaleDateString()}`}
                          />
                        ))}
                      </SectionCard>
                    </Section>
                  ) : null}

                  <Callout intent="warning" compact>
                    Your post will be visible to the Govly Network
                  </Callout>
                </CardBody>

                <CardFooter>
                  <div>
                    <Button type="submit" intent="primary" fill loading={isSubmitting}>
                      Post
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
