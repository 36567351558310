import { DistributorProfile } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['DistributorProfiles'] });

type GetDistributorProfiles = {
  params: void;
  result: DistributorProfile[];
};

export const distributorProfilesApi = api.injectEndpoints({
  endpoints: build => ({
    getDistributorProfiles: build.query<GetDistributorProfiles['result'], GetDistributorProfiles['params']>({
      query: () => ({ url: '/v2/distributor_profiles' }),
      providesTags: ['DistributorProfiles']
    })
  })
});

export const { useGetDistributorProfilesQuery } = distributorProfilesApi;
