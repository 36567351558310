import React from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikContextType, FormikProps, useFormikContext } from 'formik';
import { Button, Callout, CardList, CheckboxCard, EntityTitle, Icon, NonIdealState, Tag } from '@blueprintjs/core';

import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { useCreateCustomQuoteMutation } from '@/api/customQuotesApi';
import { useGetCustomQuoteTemplatesForOrgQuery } from '@/api/customQuoteTemplatesApi';
import { useGetQuotesForWorkspaceQuery } from '@/api/quotesApi';
import { Card, CardBody, CardSection } from '@/app/atoms/Card/Card';
import { SelectInput } from '@/app/atoms/inputs/SelectInput/SelectInput';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useOppWorkspaceStoreRef } from '@/app/organisms/OppWorkspacePageContents/useOppWorkspaceStore';
import { QuoteShow } from '@/types/__generated__/GovlyApi';

import { useOppWorkspaceQuoteListItem } from '../OppWorkspaceQuoteForm/useOppWorkspaceQuoteListItem';

export const WorkspaceToolboxDraftQuoteButton = () => {
  const store = useOppWorkspaceStoreRef();

  return (
    <Button
      icon={<Icon icon="clipboard" className="text-red-500" />}
      minimal
      onClick={() => {
        store.setState({
          toolboxDialogProps: {
            isOpen: true,
            title: 'Draft a Quote',
            children: <DrawerBody />
          }
        });
      }}
    >
      Draft a quote
    </Button>
  );
};

type FormValues = {
  includedQuoteIds: string[];
  customQuoteTemplateId: string;
};

type FormContext = FormikContextType<FormValues>;

const toggleQuoteSelection = (id: string, values: FormContext['values'], setValues: FormContext['setValues']) => {
  const ids = values.includedQuoteIds;
  const idx = ids.indexOf(id);
  if (idx < 0) {
    setValues({ ...values, includedQuoteIds: [...ids, id] });
  } else {
    const idx = ids.indexOf(id);
    const without = [...ids.slice(0, idx), ...ids.slice(idx + 1)];
    setValues({ ...values, includedQuoteIds: without });
  }
};

function QuoteListItem({ quote }: { quote: QuoteShow }) {
  const { setValues, values } = useFormikContext<FormValues>();

  const disabled = quote.llmExtractStatus !== 'completed';
  const selected = values.includedQuoteIds.includes(quote.id);
  const { title, subtext } = useOppWorkspaceQuoteListItem(quote);

  return (
    <CheckboxCard
      checked={selected}
      onChange={() => toggleQuoteSelection(quote.id, values, setValues)}
      disabled={disabled}
      alignIndicator="right"
    >
      <EntityTitle fill title={title} subtitle={subtext} icon="document" />
    </CheckboxCard>
  );
}

const DrawerBody = () => {
  const store = useOppWorkspaceStoreRef();

  const workspaceId = store.useStore(s => s.workspaceId);
  const organizationId = useCurrentUserAttribute('organizationId');

  const [createCustomQuote, _] = useCreateCustomQuoteMutation();
  const { data: quotes, isLoading: isLoadingQuotes } = useGetQuotesForWorkspaceQuery({ workspaceId });
  const { data: templates, isLoading: isLoadingTemplates } = useGetCustomQuoteTemplatesForOrgQuery({ organizationId });

  const isLoadingDeps = isLoadingQuotes || isLoadingTemplates;

  const handleSubmit = async (values: FormValues) => {
    await createCustomQuote({ ...values, workspaceId });

    store.setState({
      shouldPollCustomQuotes: true,
      toolboxDialogProps: { isOpen: false }
    });
  };

  return (
    <Formik
      initialValues={{ includedQuoteIds: [] as string[], customQuoteTemplateId: '' }}
      validationSchema={yup.object({
        includedQuoteIds: yup.array().required('You must select at least one quote'),
        customQuoteTemplateId: yup.string().min(1).required('You must select a template')
      })}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<FormValues>) => (
        <Form>
          <Card>
            <CardBody className="flex space-y-0 flex-col gap-y-4 items-center">
              {isLoadingDeps || templates == null || quotes == null ? (
                <Loading type="stacked-list" />
              ) : (
                <div className="flex flex-col gap-y-2 items-start w-full">
                  {quotes.length === 0 ? (
                    <NonIdealState
                      layout="vertical"
                      title="No Extracted Line Items"
                      description={
                        <span>
                          To draft a quote using extracted line items,
                          <br />
                          Use &lsquo;Extract Line Items&rsquo; in the workspace to get started.
                        </span>
                      }
                    />
                  ) : (
                    <>
                      <Callout icon="info-sign">Draft a quote from extracted line items.</Callout>
                      <CardList compact className="max-h-56 overflow-y-auto">
                        {quotes && quotes.map(q => <QuoteListItem key={q.id} quote={q} />)}
                      </CardList>
                    </>
                  )}
                  {templates.length === 0 ? (
                    <NonIdealState
                      layout="vertical"
                      title="No Templates Found"
                      description="No export templates could be found, contact support."
                    />
                  ) : (
                    <SelectInput
                      defaultButtonText="Select Quote Template"
                      name="customQuoteTemplateId"
                      items={templates}
                      labelAttr="name"
                      valueAttr="id"
                      filterable={false}
                      itemRenderer={(item, props) => (
                        <div
                          key={item.id}
                          role="list-item"
                          className="p-2 divide-y cursor-pointer hover:bg-gray-50"
                          onClick={props.handleClick}
                        >
                          <EntityTitle
                            title={item.name}
                            subtitle={item.description}
                            tags={item.isGlobal ? <Tag intent="primary">Global</Tag> : null}
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
              )}
            </CardBody>
            <CardSection className="py-2 flex flex-row flex-row-reverse">
              <Button intent="primary" type="submit" className="self-end" large loading={isLoadingDeps}>
                Generate
              </Button>
              {props.dirty && props.isValid && props.values.includedQuoteIds.length === 0 && (
                <Callout intent="warning" className="-my-4 -ml-4 self-start" minimal>
                  Without any line items, this drafted quote will contain only your pre-populated profile data.
                </Callout>
              )}
            </CardSection>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
