import React, { useState } from 'react';
import { Button, ButtonProps, Dialog, Tooltip, TooltipProps } from '@blueprintjs/core';

import { WorkspaceForm } from '@/app/organisms/WorkspaceForm/WorkspaceForm';
import { LimitedFollow } from '@/api/workspacesApi';

type OppDetailsCreateWorkspaceProps = {
  oppId: string;
  name: string;
  tooltipProps?: TooltipProps;
  follows?: LimitedFollow[];
  buttonProps?: ButtonProps;
};

export const OppDetailsCreateWorkspace = ({
  tooltipProps,
  follows = [],
  name,
  oppId,
  buttonProps
}: OppDetailsCreateWorkspaceProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip {...tooltipProps}>
        <Button icon="plus" onClick={() => setIsOpen(true)} {...buttonProps} />
      </Tooltip>
      <Dialog onClose={() => setIsOpen(false)} isOpen={isOpen} className="min-w-[50%] p-0">
        <WorkspaceForm
          onCancel={() => setIsOpen(false)}
          onError={() => setIsOpen(true)}
          title="Create Workspace"
          follows={follows}
          basicInputs={true}
          followersInputs={true}
          stageInputs={true}
          initialValues={{ workableId: oppId, workableType: 'Opp', name, workflowStage: 'qualifying' }}
          workableTypeForLink="opportunities"
          trackingData={{ event: 'Opp Workspace Created' }}
        />
      </Dialog>
    </>
  );
};
