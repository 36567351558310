import React, { useRef } from 'react';
import { Button, ButtonProps, Intent, Menu, MenuItem, Tag, Tooltip } from '@blueprintjs/core';
import { startCase } from 'lodash-es';
import { ItemListRendererProps, Omnibar } from '@blueprintjs/select';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useHotkeys } from 'react-hotkeys-hook';

import { useGetCurrentUserQuery } from '@/api/currentUserApi';
import { TextLoading } from '@/app/atoms/Loading/Loading';
import { OrganizationIndex, OrganizationUserCurrentUser } from '@/types/__generated__/GovlyApi';
import { cn } from '@/app/lib/cn';
import { useDeviceWidth } from '@/app/hooks/useDeviceWidth';
import { useSwitchOrganization } from '@/app/molecules/OrganizationSwitcher/useSwitchOrganization';

import { useOrganizationSwitcherStore, useOrganizationSwitcherStore as useStore } from './useOrganizationSwitcherStore';

export type OrganizationSwitcherProps = {
  organizations: OrganizationIndex[];
  onSelectOrganization: (org: OrganizationIndex) => void;
  currentUser: OrganizationUserCurrentUser;
};

const OMNIBAR_FIXED_WIDTH = 500;

export const OrganizationSwitcher = () => {
  const { organizations, onSwitch, currentUser } = useSwitchOrganization();
  if (!currentUser || !currentUser.multipleOrganizations) return null;

  return (
    <OrganizationSwitcherUI organizations={organizations} onSelectOrganization={onSwitch} currentUser={currentUser} />
  );
};

export const OrganizationSwitcherUI = ({
  organizations,
  onSelectOrganization,
  currentUser
}: OrganizationSwitcherProps) => {
  const isOpen = useStore.use.isOpen();
  const isSwitching = useStore.use.isSwitching();

  useHotkeys('meta+shift+o', () => useStore.setState({ isOpen: true }));
  useHotkeys('esc', () => useStore.setState({ isOpen: false }), { enabled: isOpen });

  const { width } = useDeviceWidth();

  return (
    <Omnibar
      className={cn('max-w-full', { 'left-0': width < OMNIBAR_FIXED_WIDTH })}
      isOpen={isOpen}
      onClose={() => useOrganizationSwitcherStore.setState({ isOpen: false })}
      items={organizations}
      itemPredicate={(query, org) => {
        return `${org.name} ${org.subscriptionType} ${org.id}`.toLowerCase().includes(query.toLowerCase());
      }}
      // Required to override the `null` passed by the library
      initialContent={undefined}
      itemsEqual={(a, b) => a.id === b.id}
      itemDisabled={org => org.id === currentUser.organizationId}
      onItemSelect={org => {
        useOrganizationSwitcherStore.setState({ isSwitching: true });
        onSelectOrganization?.(org);
      }}
      itemListRenderer={props => <List {...props} />}
      itemRenderer={(org, options) => (
        <MenuItem
          key={org.id}
          {...{
            active: options.modifiers.active,
            disabled: options.modifiers.disabled,
            onClick: options.handleClick,
            onFocus: options.handleFocus,
            ref: options.ref
          }}
          text={isSwitching ? <TextLoading className="w-full" /> : `${org.id} - ${org.name}`}
          labelElement={
            <Tag
              intent={
                (
                  { free: 'primary', trial: 'warning' } as {
                    [k in OrganizationIndex['subscriptionType']]: Intent;
                  }
                )[org.subscriptionType] || 'success'
              }
            >
              {startCase(org.subscriptionType)}
            </Tag>
          }
        />
      )}
    />
  );
};

export type OrganizationSwitcherButtonProps = Partial<ButtonProps> & { asChild?: boolean };

export const OrganizationSwitcherButton = ({ asChild, ...props }: OrganizationSwitcherButtonProps) => {
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  if (asChild) {
    return React.cloneElement(props.children as React.ReactElement, {
      role: 'button',
      onClick: () => useOrganizationSwitcherStore.setState({ isOpen: true })
    });
  }

  return (
    <Tooltip content="meta + shift + o" hoverOpenDelay={1000}>
      <Button
        onClick={() => useOrganizationSwitcherStore.setState({ isOpen: true })}
        icon="office"
        loading={currentUserLoading}
        text={props.children ? undefined : (props.text ?? currentUser?.organizationName ?? 'Organizations')}
        {...props}
      />
    </Tooltip>
  );
};

const ROW_HEIGHT = 38.5;

const List = (props: ItemListRendererProps<OrganizationIndex>) => {
  const parentRef = useRef<HTMLUListElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: props.filteredItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ROW_HEIGHT
  });

  return (
    <Menu ulRef={parentRef} className="min-h-[40px] overflow-y-auto">
      <div className="relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
        {props.filteredItems.length === 0 && <MenuItem disabled={true} text="No results." />}
        {rowVirtualizer.getVirtualItems().map(virtualRow => (
          <div
            key={virtualRow.index}
            className="absolute top-0 left-0 w-full"
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`
            }}
          >
            {props.renderItem(props.filteredItems[virtualRow.index], virtualRow.index)}
          </div>
        ))}
      </div>
    </Menu>
  );
};
