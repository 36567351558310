import React from 'react';
import { useParams, useSearchParams } from 'react-router';

import type { DrawerNavTab, NavItem } from '@/app/organisms/AuthenticatedNav/AuthenticatedNavItemsUtils';
import { DRAWER_PARAMS } from '@/app/organisms/AppDrawer/constants';
import { useGetOppTeamingBoardPostsQuery } from '@/api/oppTeamingBoardPostsApi';

export type OppTeamingBoardTagContentProps = {
  node: NavItem | DrawerNavTab;
  index: number;
};

export const OppTeamingBoardTagContent = (_: OppTeamingBoardTagContentProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const id = params.id ?? searchParams.get(DRAWER_PARAMS.id) ?? '';
  const query = useGetOppTeamingBoardPostsQuery({ oppId: id, view: 'index' });
  const count = query.data?.length ?? 0;
  return count > 0 ? <span>{count}</span> : null;
};
