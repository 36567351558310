import React from 'react';
import Markdown from 'react-markdown';
import { Tag } from '@blueprintjs/core';

import { Card } from '@/app/atoms/Card/Card';
import { useGetAwardQuery } from '@/api/awardsApiV2';
import { CardList, CardListItem, CardListItemProps } from '@/app/molecules/CardList/CardList';
import { asCurrency, asPercentage } from '@/app/lib/numbers';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { DescriptionListField } from '@/app/molecules/DescriptionListField/DescriptionListField';
import { formatAddress } from '@/app/lib/address';
import { Loading } from '@/app/atoms/Loading/Loading';
import { getAwardStatus } from '@/app/organisms/AwardDetailsV2/utils';
import { AwardDetailsTransactionsTable } from '@/app/organisms/AwardDetailsV2/AwardDetailsTransactionsTable';
import { AwardDetailsSubawardsTable } from '@/app/organisms/AwardDetailsV2/AwardDetailsSubawardsTable';
import { AwardDetailsAmountChart } from '@/app/organisms/AwardDetailsV2/AwardDetailsAmountChart';
import { AwardDetailsContractActivityGraph } from '@/app/organisms/AwardDetailsV2/AwardDetailsContractActivityGraph';
import { ErrorBoundary } from '@/app/atoms/ErrorBoundary/ErrorBoundary';
import { CardError } from '@/app/atoms/ErrorFallback/CardError';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';

// const IGNORED_SEARCH_TAGS = new Set(['NOT BUNDLED']);

const cardListItemProps: CardListItemProps = {
  collapsible: true,
  compact: false,
  defaultIsOpen: false,
  titleRenderer: props => <CardHeadingSmall {...props} />,
  className: 'space-y-2 pt-2'
};

export const AwardDetails = ({ uniqueKey }: { uniqueKey: string }) => {
  const { data: award, isLoading } = useGetAwardQuery({ uniqueKey, searchType: 'fed' });

  if (isLoading) return <Loading type="card-list" />;
  if (!award) return null;

  const recipientAddress = formatAddress({
    city: award.recipientCity,
    state: award.recipientRegion,
    zip: award.recipientPostalCode,
    country: award.recipientCountry
  });

  const [total, potential, obligated, outlayed] = [
    award.awardAmount,
    award.ceilingAmount,
    award.obligatedAmount,
    award.paidAmount
  ].map(val => (val ? parseFloat(val) : 0));

  const { status, statusDetail, intent } = getAwardStatus({ award });

  return (
    <>
      <CardList
        titleRenderer={props => <Tag {...props} />}
        title={[award.awardType || award.idvType || award.awardOrIdvFlag, award.identifier].join(' ')}
        rightElement={
          <Tag intent={intent} minimal>
            {status} {statusDetail}
          </Tag>
        }
      >
        {!!award.title && (
          <CardListItem
            title={award.title}
            className="pt-0"
            titleRenderer={cardListItemProps.titleRenderer}
            defaultIsOpen
          />
        )}

        {!!award.aiSummary && (
          <CardListItem
            {...cardListItemProps}
            icon={<LlmGeneratedIndicator icon="generate" />}
            title="Summary"
            subtitle={'AI generated summary that considers all award details and transactions.'}
            defaultIsOpen
          >
            <Markdown className="prose prose-sm pt-0">{award.aiSummary}</Markdown>
          </CardListItem>
        )}

        <CardListItem
          {...cardListItemProps}
          icon="document"
          title={!award.aiSummary ? 'Description' : 'Original Description'}
          defaultIsOpen={!award.aiSummary}
        >
          <Markdown className="prose prose-sm pt-0">{award.description}</Markdown>
        </CardListItem>

        <CardListItem {...cardListItemProps} title="Agency Details" icon="office">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <DescriptionListField
              label="Awarding Agency"
              value={award.awardingAgencyName}
              cols={1}
              tooltipContent={
                <p>
                  The <b>awarding agency</b> for an award is the agency that creates and administers the award. In most
                  cases, the awarding and funding agency are the same. The division of funding and awarding agencies
                  benefits many smaller agencies, who may not have the staff available to administer an award.
                </p>
              }
            />
            <DescriptionListField
              label="Funding Agency"
              value={award.fundingAgencyName}
              cols={1}
              tooltipContent={
                <p>
                  The <b>funding agency</b> is the agency that pays for the award. In most cases, the awarding and
                  funding agency are the same. The division of funding and awarding agencies benefits many smaller
                  agencies, who may not have the staff available to administer an award.
                </p>
              }
            />

            <DescriptionListField label="Awarding Sub-Agency" value={award.awardingSubAgencyName} cols={1} />
            <DescriptionListField label="Funding Sub-Agency" value={award.fundingSubAgencyName} cols={1} />
            <DescriptionListField label="Awarding Office" value={award.awardingOfficeName} cols={1} />
            <DescriptionListField label="Funding Office" value={award.fundingOfficeName} cols={1} />
          </dl>
        </CardListItem>

        <CardListItem {...cardListItemProps} title="Recipient Details" icon="people">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <DescriptionListField label="Recipient" value={award.recipientName} cols={1} />
            <DescriptionListField label="Parent Recipient" value={award.recipientParentName} cols={1} />
            <DescriptionListField label="Recipient Address" value={recipientAddress.join(', ')} cols={1} />
            {/* <DescriptionListField
              label="Business Types"
              value={award.searchTags?.filter(tag => !IGNORED_SEARCH_TAGS.has(tag)).join(', ')}
              cols={1}
            /> */}
            <DescriptionListField
              label="Congressional District"
              value={
                award.placeOfPerformanceCongressionalCode &&
                award.recipientRegion &&
                `${award.placeOfPerformanceCongressionalCode}-${award.recipientRegion}`
              }
              cols={1}
            />
          </dl>
        </CardListItem>

        <CardListItem {...cardListItemProps} title="Award Amounts" icon="dollar">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <DescriptionListField
              label="Outlayed Amount"
              value={asCurrency(outlayed)}
              cols={1}
              tooltipContent={
                <p>
                  <b>Outlayed Amount</b> (or Spent Amount) represents the actual funds that have been disbursed or
                  spent. It indicates the financial resources that have been paid out to the recipient as they carry out
                  the work or services described in the award or contract.
                </p>
              }
            />
            <DescriptionListField
              label="Obligated Amount"
              value={asCurrency(obligated)}
              cols={1}
              tooltipContent={
                <p>
                  <b>Obligated Amount</b> is the specific amount of money that has been committed by the federal
                  government through a definite agreement or contract. This amount has been legally set aside for
                  payment to the recipient for the work or services specified.
                </p>
              }
            />
            <DescriptionListField
              label="Current Award Amount"
              value={asCurrency(total)}
              cols={1}
              tooltipContent={
                <p>
                  <b>Current Award Amount</b> is the total amount of funds that have been obligated to date under a
                  particular award or contract. This figure includes the initial award amount plus any modifications,
                  amendments, or additional funding that has been committed since the initial award.
                </p>
              }
            />
            <DescriptionListField
              label="Potential Award Amount"
              value={asCurrency(potential)}
              cols={1}
              tooltipContent={
                <p>
                  <b>Potential Award Amount</b> refers to the maximum possible amount that could be awarded or obligated
                  over the life of the contract or grant. It&apos;s an estimate of the total funds that could be made
                  available under certain conditions, including options or future phases that might be enacted.
                </p>
              }
            />

            <DescriptionListField
              label="Remaining Potential"
              value={asCurrency(potential - outlayed)}
              tooltipContent={'(Potential Award Amount) - (Outlayed Amount)'}
            />

            <DescriptionListField
              label="Percent of Potential Remaining"
              value={asPercentage((potential - outlayed) / potential)}
              tooltipContent={'(Remaining Potential) / (Potential Award Amount)'}
            />
          </dl>
        </CardListItem>
      </CardList>

      <ErrorBoundary action="AwardDetailsAmountChart" fallback={<CardError title="Award Amounts" />}>
        <Card className="px-4">
          <AwardDetailsAmountChart award={award} />
        </Card>
      </ErrorBoundary>

      {award.awardOrIdvFlag === 'AWARD' && (
        <ErrorBoundary action="AwardDetailsContractActivityGraph" fallback={<CardError title="Contract Activity" />}>
          <Card className="px-4">
            <AwardDetailsContractActivityGraph award={award} />
          </Card>
        </ErrorBoundary>
      )}

      {award.awardOrIdvFlag === 'AWARD' && <AwardDetailsTransactionsTable award={award} />}
      {award.awardOrIdvFlag === 'AWARD' && <AwardDetailsSubawardsTable award={award} />}
    </>
  );
};
