import { createNewThread } from '@/app/organisms/Assistant/useAssistantStore';

import { useAssistantContext } from './AssistantProvider';

export const useHandleNewThreadRequest = () => {
  const { useCreateThreadMutation, createThreadParams, welcomeMessageFactory, store } = useAssistantContext();

  const [createThread, { isLoading: createThreadIsLoading }] = useCreateThreadMutation();

  const handleNewThreadRequest = async () => {
    await createNewThread(store, {
      welcomeMessage: welcomeMessageFactory.refresh(),
      createThreadPromise: createThread(createThreadParams).unwrap()
    });
  };

  return { handleNewThreadRequest, isLoading: createThreadIsLoading };
};
