import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';
import { match } from 'ts-pattern';

import { AwardShow, AwardTransactionIndex } from '@/types/__generated__/GovlyApi';
import { useGetAwardTransactionsQuery } from '@/api/awardsApiV2';
import { Loading } from '@/app/atoms/Loading/Loading';
import 'highcharts/es-modules/masters/highcharts-more.src.js';
import 'highcharts/es-modules/masters/modules/dumbbell.src.js';
import 'highcharts/es-modules/masters/modules/lollipop.src.js';
import { asCurrency } from '@/app/lib/numbers';

export type AwardDetailsContractActivityGraphProps = {
  award: AwardShow;
};

export const AwardDetailsContractActivityGraph = ({ award }: AwardDetailsContractActivityGraphProps) => {
  const transactionsQuery = useGetAwardTransactionsQuery({ uniqueKey: award.uniqueKey, searchType: 'fed' });

  const startDate = new Date(award.periodOfPerformanceStartDate ?? new Date()).getTime();
  const endDate = new Date(award.periodOfPerformancePotentialEndDate ?? new Date()).getTime();
  const potential = parseFloat(award.ceilingAmount ?? '0');

  const obligationData = transactionsQuery.data?.results.reduce(
    (acc, el) => {
      const last = acc.pop();
      const nextVal = parseFloat(el.obligatedAmount ?? '0');
      const nextDate = new Date(el.actionDate ?? new Date()).getTime();
      if (!last) {
        acc.push({ x: nextDate, y: nextVal, ...el });
        return acc;
      }
      const { x: lastDate, y: lastVal, ...lastRest } = last;
      acc.push({ x: lastDate, y: lastVal, ...lastRest }, { x: nextDate, y: lastVal + nextVal, ...el });
      return acc;
    },
    [] as ({ x: number; y: number } & AwardTransactionIndex)[]
  );

  const outlayedData = transactionsQuery.data?.results.map(el => {
    const nextVal = parseFloat(el.awardAmount ?? '0');
    const nextDate = new Date(el.actionDate ?? new Date()).getTime();
    return { x: nextDate, y: nextVal, ...el };
  });

  const options: Highcharts.Options = useMemo(() => {
    return {
      title: { text: 'Contract Activity', align: 'left' },
      chart: {
        zooming: {
          singleTouch: true,
          type: 'x'
        }
      },
      accessibility: {
        keyboardNavigation: {
          seriesNavigation: {
            mode: 'serialize'
          }
        }
      },
      legend: { enabled: false },
      tooltip: {
        shared: false,
        crosshairs: true,
        formatter: function () {
          return match<typeof this, string | false>(this)
            .with({ series: { userOptions: { type: 'lollipop' } } }, () => false)
            .with({ series: { userOptions: { type: 'line' } } }, () => `Potential: <b>${asCurrency(potential)}</b>`)
            .with({ series: { userOptions: { type: 'area' }, name: 'Outlayed' } }, () => {
              const transaction = this.point as unknown as AwardTransactionIndex;
              const meta = transaction.modificationNumber ? `Modification: ${transaction.modificationNumber}` : '';
              const date = transaction.actionDate
                ? `Action date: ${new Date(transaction.actionDate).toLocaleDateString()}`
                : '';
              return [`${this.series.name}: <b>${asCurrency(this.y)}</b>`, date, meta].filter(Boolean).join('<br>');
            })
            .with({ series: { userOptions: { type: 'area' }, name: 'Obligations' } }, ({ point, y }) => {
              const transaction = point as unknown as AwardTransactionIndex;
              const meta = transaction.modificationNumber ? `Modification: ${transaction.modificationNumber}` : '';
              const date = transaction.actionDate
                ? `Action date: ${new Date(transaction.actionDate).toLocaleDateString()}`
                : '';
              const obligation = transaction.obligatedAmount
                ? `Obligation: ${asCurrency(transaction.obligatedAmount)}`
                : '';

              const percentage = Math.round(((y ?? 0) / potential) * 100);
              return [
                `${this.series.name}: <b>${asCurrency(y)}</b>`,
                date,
                obligation,
                meta,
                `${percentage}% of Potential Award Amount`
              ]
                .filter(Boolean)
                .join('<br>');
            })
            .otherwise(() => false);
        }
      },
      yAxis: {
        title: { text: null },
        crosshair: true
      },
      xAxis: {
        type: 'datetime',
        crosshair: true
      },
      plotOptions: {
        area: { fillOpacity: 0.5 }
      },
      series: [
        { type: 'area' as const, name: 'Obligations', data: obligationData },
        { type: 'area' as const, name: 'Outlayed', data: outlayedData },
        {
          type: 'lollipop' as const,
          marker: { enabled: false, symbol: 'circle' },
          data: [
            {
              x: startDate,
              y: potential + potential / 4,
              dataLabels: {
                enabled: true,
                formatter: function (): string {
                  const { x } = this as unknown as { x: number };
                  return `Start date: ${new Date(x).toLocaleDateString()}`;
                }
              }
            }
          ]
        },
        {
          type: 'lollipop' as const,
          marker: { enabled: false, symbol: 'circle' },
          data: [
            {
              x: endDate,
              y: potential + potential / 4,
              dataLabels: {
                enabled: true,
                formatter: function (): string {
                  const { x } = this as unknown as { x: number };
                  return `End: ${new Date(x).toLocaleDateString()}`;
                }
              }
            }
          ]
        },
        {
          type: 'line' as const,
          marker: { symbol: 'circle' },
          data: [
            { x: startDate, y: potential },
            { x: endDate, y: potential }
          ]
        }
      ]
    };
  }, [obligationData, outlayedData, startDate, potential, endDate]);

  if (transactionsQuery.isLoading) return <Loading />;

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
