import React, { useEffect, useRef } from 'react';
import { Button, ButtonGroup, Label } from '@blueprintjs/core';
import { Formik, Form, Field, useFormikContext } from 'formik';

import { useSendNewThreadMessage } from '@/app/organisms/Assistant/useSendNewThreadMessage';

import { useAssistantContext } from './AssistantProvider';

type AssistantFormProps = {
  className?: string;
};

const AssistantForm = ({ className }: AssistantFormProps) => {
  const sendNewMessage = useSendNewThreadMessage();

  return (
    <div>
      <Formik
        initialValues={{ content: '' }}
        onSubmit={({ content }, { setValues }) => {
          sendNewMessage(content);
          setValues({ content: '' });
        }}
      >
        <InnerAssistantForm className={className} />
      </Formik>
    </div>
  );
};

const InnerAssistantForm = ({ className }: { className?: string }) => {
  const sendNewMessage = useSendNewThreadMessage();
  const { placeholder, defaultPrompts } = useAssistantContext();
  const { submitForm } = useFormikContext();

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await submitForm();
    }
  };

  // ensure the current input gets focus on mount
  const contentInput = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (contentInput.current) {
      contentInput.current.focus();
      const length = contentInput.current.value.length;
      contentInput.current.setSelectionRange(length, length);
    }
  }, [contentInput]);

  return (
    <Form className={className} onKeyDown={handleKeyDown}>
      <Field
        as="textarea"
        name="content"
        id="content"
        className="resize-none rounded border w-full p-2 h-20"
        placeholder={placeholder}
        innerRef={contentInput}
      />
      <div className="flex flex-row justify-between mt-4">
        {defaultPrompts && (
          <ButtonGroup vertical minimal alignText="left">
            <Label className="font-semibold">Need some inspiration?</Label>
            {defaultPrompts.map((prompt, i) => (
              <Button key={i} tabIndex={-1} icon="clean" onClick={() => sendNewMessage(prompt)}>
                {prompt}
              </Button>
            ))}
          </ButtonGroup>
        )}
        <Button intent="primary" icon="clean" className="self-start" type="submit">
          Ask
        </Button>
      </div>
    </Form>
  );
};

export { AssistantForm };
