import React from 'react';
import { Button, MenuItem, Card as BpCard, ButtonProps } from '@blueprintjs/core';
import { ArrayHelpers, FieldArray, useField } from 'formik';
import { ItemRenderer, Select, SelectProps } from '@blueprintjs/select';
import { match } from 'ts-pattern';

import { AvatarWithName } from '@/app/molecules/AvatarWithName/AvatarWithName';
import { SimpleRecipient, simpleRecipientItemPredicate } from '@/app/organisms/RecipientSelect/utils';
import { cn } from '@/app/lib/cn';

type Props = {
  type: 'partner' | 'distributor' | 'guest';
  helpers: ArrayHelpers;
  index: number;
  name: string;
  recipients: SimpleRecipient[];
  buttonProps: ButtonProps;
  selectProps?: Partial<SelectProps<SimpleRecipient>>;
  SelectedItem?: React.ComponentType<{
    contact: SimpleRecipient;
    index: number;
    helpers: ArrayHelpers;
    type: Props['type'];
    parentIndex: number;
  }>;
};
export type { Props as RecipientSelectGroupItemProps };

export const RecipientSelectGroupItem = ({
  type,
  helpers,
  index,
  name,
  recipients,
  buttonProps,
  selectProps,
  SelectedItem: SelectedItemProp = SelectedItem
}: Props) => {
  const [field] = useField<SimpleRecipient[]>(`recipients.${index}.recipients`);

  const contactEmails = new Set(field.value.map(contact => contact.email));
  const remainingContacts = recipients.filter(contact => !contactEmails.has(contact.email));

  return (
    <BpCard
      compact
      className={cn(
        'flex items-center justify-between border-t-4',
        match(type)
          .with('partner', () => 'border-t-green-500')
          .with('distributor', () => 'border-t-blue-500 ')
          .with('guest', () => 'border-t-orange-500')
          .exhaustive()
      )}
      aria-label={`${type} group`}
    >
      <FieldArray name={`recipients.${index}.recipients`}>
        {h => (
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between w-full">
              <Select
                items={remainingContacts}
                itemRenderer={itemRenderer}
                onItemSelect={item => h.push(item)}
                itemPredicate={simpleRecipientItemPredicate}
                {...selectProps}
              >
                <Button small minimal text={name} {...buttonProps} rightIcon="chevron-down" />
              </Select>
              <Button
                intent="danger"
                small
                minimal
                icon="cross"
                aria-label={`Remove ${type}`}
                onClick={() => helpers.remove(index)}
              />
            </div>

            <div className="space-y-4">
              {field.value.map((contact, i) => (
                <SelectedItemProp key={i} contact={contact} index={i} helpers={h} type={type} parentIndex={index} />
              ))}
            </div>
          </div>
        )}
      </FieldArray>
    </BpCard>
  );
};

const itemRenderer: ItemRenderer<SimpleRecipient> = (item, { handleClick }) => {
  return (
    <MenuItem
      key={item.email}
      text={item.new && item.icon === 'plus' ? 'Add new' : item.email}
      onClick={handleClick}
      icon={item.icon}
    />
  );
};

const SelectedItem: NonNullable<Props['SelectedItem']> = ({ contact, index, helpers, type }) => (
  <div className="flex items-center w-full justify-between" aria-label={`${type} contact`}>
    <AvatarWithName
      size="sm"
      email={contact.email}
      name={contact.name}
      initials={contact.initials}
      imgSrc={contact.avatarUrl}
    />
    <Button
      icon="cross"
      small
      minimal
      intent="danger"
      aria-label={`Remove ${contact.email}`}
      onClick={() => helpers.remove(index)}
    />
  </div>
);
