import React from 'react';
import Markdown from 'react-markdown';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';

export const BudgetSummary = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !governmentEntity?.latestUpload?.summary?.text) return null;

  const fiscalYear = governmentEntity.latestUpload.fiscalYear;

  return (
    <Card
      title={
        <span className="space-x-2">
          <LlmGeneratedIndicator icon="generate" />
          Projected Budget Summary{fiscalYear ? ` (FY${fiscalYear})` : ''}
        </span>
      }
      titleRenderer={CardHeadingSmall}
      collapsible={true}
    >
      <CardBody>
        <Markdown className="prose prose-sm">{governmentEntity.latestUpload.summary.text}</Markdown>
      </CardBody>
    </Card>
  );
};
