import React, { useState, useEffect, useMemo } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { CSVLink } from 'react-csv';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';

import { ErrorBoundary } from '@/app/atoms/ErrorBoundary/ErrorBoundary';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { Loading } from '@/app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from '@/app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useLazyOppAggregationQuery } from '@/api/oppsApi';
import { CardError } from '@/app/atoms/ErrorFallback/CardError';

type VendorAgg = [string, number][];

type AggregationQueryResponse = {
  aggs?: {
    topVendorsAgg: VendorAgg;
    trendingVendorsAgg: VendorAgg;
  };
};

type Aggregations = 'topVendorsAgg' | 'trendingVendorsAgg';
const slices: Array<{ key: Aggregations; label: string }> = [
  { key: 'topVendorsAgg', label: 'Top Vendors' },
  { key: 'trendingVendorsAgg', label: 'Trending Vendors' }
];

const extractedSeriesData = (agg?: VendorAgg): { seriesData: Highcharts.SeriesBarOptions[]; csv: string } => {
  if (!agg) {
    return { seriesData: [{ type: 'bar', name: 'Value', data: [] }], csv: '' };
  }

  const csv = ['Vendor,Count', ...agg.map(point => point.join(','))].join('\n');

  return { seriesData: [{ type: 'bar', name: 'Value', data: agg }], csv };
};

const TITLE = 'Vendors';

const GovernmentEntityVendorChart = ({ id }: { id: string }) => {
  const [viewAgg, setViewAgg] = useState<Aggregations>('topVendorsAgg');

  const [getOppsAggregation, { data = {}, isLoading, isError }] = useLazyOppAggregationQuery();

  useEffect(() => {
    getOppsAggregation({
      buyerIds: [id],
      aggs: ['topVendorsAgg', 'trendingVendorsAgg'],
      preprocessor: 'Opp::Analytics::VendorAggPreprocessor'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agg = (data as AggregationQueryResponse).aggs?.[viewAgg];

  const { seriesData, csv } = extractedSeriesData(agg);

  const options: Highcharts.Options = useMemo(() => {
    return {
      series: seriesData,
      plotOptions: {
        bar: {
          colorByPoint: true
        },
        column: {
          colorByPoint: true
        }
      },
      title: {
        text: undefined
      },
      chart: {
        type: 'bar',
        height: 750
      },
      legend: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: undefined
        }
      }
    };
  }, [seriesData]);

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card
        title="Vendors"
        rightElement={
          csv.length ? (
            <CSVLink data={csv} filename={`govly-posted-at-results.csv`} className="text-gray-400 no-underline">
              <Button outlined icon="download">
                Download Data
              </Button>
            </CSVLink>
          ) : undefined
        }
      >
        <CardBody>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </CardBody>
        <CardBody className="text-center">
          <ButtonGroup>
            {slices.map(({ key, label }) => (
              <Button key={key} active={viewAgg === key} onClick={() => setViewAgg(key)} text={label} />
            ))}
          </ButtonGroup>
        </CardBody>
      </Card>
    </>
  );
};

const WithErrorBoundary = (props: React.ComponentProps<typeof GovernmentEntityVendorChart>) => (
  <ErrorBoundary action="GovernmentEntityVendorChart" fallback={<CardError title={TITLE} />}>
    <GovernmentEntityVendorChart {...props} />
  </ErrorBoundary>
);

export { WithErrorBoundary as GovernmentEntityVendorChart };
