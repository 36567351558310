import React, { useMemo } from 'react';
import { Button, NonIdealState } from '@blueprintjs/core';
import { mkConfig, generateCsv, download } from 'export-to-csv';

import { useMetaTags } from '@/app/hooks/useMetaTags';
import { NotFound } from '@/app/molecules/NotFound/NotFound';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { Main } from '@/app/atoms/Main/Main';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { AppLoading } from '@/app/atoms/AppLoading/AppLoading';
import { Card } from '@/app/atoms/Card/Card';

import { GovernmentEntityChat } from './GovernmentEntityChat';
import { GovernmentEntityBreadcrumbs } from './GovernmentEntityBreadcrumbs';
import { AdditionalDetails } from './GovernmentEntityDetails/AdditionalDetails';
import { BudgetUploads } from './GovernmentEntityDetails/BudgetUploads';
import { Description } from './GovernmentEntityDetails/Description';
import { Hierarchy } from './GovernmentEntityDetails/Hierarchy';
import { Title } from './GovernmentEntityDetails/Title';
import { BudgetSummary } from './GovernmentEntityDetails/BudgetSummary';
import { BudgetRequestsChart } from './GovernmentEntityDetails/BudgetRequestsChart';
import { CategoryRequestsChart } from './GovernmentEntityDetails/CategoryRequestsChart';

interface BudgetItem {
  name: string;
  amount: string;
  description: string;
}

interface BudgetSummary {
  totalFunding: string;
  items: BudgetItem[];
}

const parseBudgetMarkdown = (markdown: string): BudgetSummary => {
  const items: BudgetItem[] = [];
  let totalFunding = '';

  const totalRegex = /\*\*Total funding requested:\s*(\$[\d,.]+ billion)\*\*/;
  const totalMatch = markdown.match(totalRegex);
  if (totalMatch) {
    totalFunding = totalMatch[1];
  }

  const itemRegex = /- \*\*(.*?)\s*\((\$[\d,.]+ (?:billion|million)\)):\*\*\s*(.*?)(?=(?:\n- \*\*|$))/gs;

  let match;
  while ((match = itemRegex.exec(markdown)) !== null) {
    items.push({
      name: match[1].trim(),
      amount: match[2].trim().replace(')', ''),
      description: match[3].trim()
    });
  }

  return {
    totalFunding,
    items
  };
};

const downloadAsCSV = (budget: BudgetSummary, fiscalYear?: string, governmentEntityName?: string) => {
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: `${governmentEntityName?.toLowerCase().replace(/\s+/g, '-')}-budget-${fiscalYear || 'summary'}`
  });

  const formattedData = [
    {
      title: `${governmentEntityName} Budget Summary`,
      amount: '',
      description: ''
    },
    {
      title: fiscalYear ? `Fiscal Year ${fiscalYear}` : '',
      amount: '',
      description: ''
    },
    {
      title: 'Total Funding Requested',
      amount: budget.totalFunding,
      description: ''
    },
    ...budget.items.map(item => ({
      title: item.name,
      amount: item.amount,
      description: item.description
    }))
  ];

  const csv = generateCsv(csvConfig)(formattedData);
  download(csvConfig)(csv);
};

export const GovernmentEntityBudget = ({ id, className }: { id: string; className?: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  const budget = useMemo(() => {
    if (!governmentEntity?.latestUpload?.summary?.text) return { totalFunding: '', items: [] };
    return parseBudgetMarkdown(governmentEntity.latestUpload.summary.text);
  }, [governmentEntity?.latestUpload?.summary?.text]);

  useMetaTags(
    {
      title: governmentEntity ? `Agencies | ${governmentEntity.name}` : 'Agency Details'
    },
    [governmentEntity]
  );

  if (!id) return <NotFound />;
  if (isLoading) return <AppLoading />;
  if (!governmentEntity) return <NotFound />;

  const showCardList = !!(
    governmentEntity.url ||
    governmentEntity.ancestors?.length ||
    governmentEntity.uploads?.length ||
    governmentEntity.children?.length
  );

  const fiscalYear = governmentEntity.latestUpload?.fiscalYear;

  return (
    <Main className={className}>
      <MainColumn className="space-y-4">
        <GovernmentEntityBreadcrumbs id={id} />
        <Title id={id} />
      </MainColumn>

      <MainColumn className="lg:hidden">
        <Description id={id} />
        <AdditionalDetails id={id} />
        <BudgetUploads id={id} />
        <Hierarchy id={id} />
        <BudgetRequestsChart id={id} />
        <CategoryRequestsChart id={id} />
        <BudgetSummary id={id} />
        <GovernmentEntityChat id={id} />
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block">
        {!governmentEntity.latestUpload && (
          <Card>
            <NonIdealState className="py-8" icon="document" title="No Budget Data for this Entity" />
          </Card>
        )}
        <Description id={id} />
        <BudgetRequestsChart id={id} />
        <CategoryRequestsChart id={id} />
        <BudgetSummary id={id} />
        <GovernmentEntityChat id={id} />
      </MainColumn>

      <MainColumn columnSpan={4} className="hidden lg:block">
        {showCardList && (
          <>
            <BudgetUploads id={id} />
            {budget.items.length > 0 && (
              <Button
                className="w-full"
                large
                type="button"
                intent="primary"
                onClick={() => downloadAsCSV(budget, fiscalYear?.toString(), governmentEntity.name)}
              >
                Download Budget as CSV
              </Button>
            )}
          </>
        )}
      </MainColumn>
    </Main>
  );
};
