import React from 'react';
import { Formik, Form } from 'formik';
import { useShallow } from 'zustand/shallow';

import { useChatStore } from '@/app/organisms/Chat/useChatStore';
import { getChatMessages, makeResetMessages } from '@/app/organisms/Chat/useChatStore';

import { ChatForm, ChatFormProps } from './ChatForm';
import { useSendChatMessage } from './useSendChatMessage';

type ChatProps = {
  id: string;
  numberOfResults?: number;
} & Pick<ChatFormProps, 'placeholder' | 'questions' | 'cardProps'>;

export type ChatFormState = {
  filters: {
    entityIds?: string[];
  };
  query: string;
  useCustomPrompt: boolean;
};

export const Chat = ({ id, placeholder, questions, numberOfResults, cardProps }: ChatProps) => {
  const messages = useChatStore(useShallow(getChatMessages(id)));
  const resetMessages = makeResetMessages(id);

  const { createMessage, isLoading, ref } = useSendChatMessage({
    storeId: id,
    numberOfResults
  });

  return (
    <Formik
      initialValues={{ filters: { entityIds: [id] }, query: '', useCustomPrompt: false }}
      onSubmit={({ query, useCustomPrompt, filters: { entityIds } }, { setFieldValue }) =>
        createMessage({
          entityIds,
          message: query,
          clearQuery: () => setFieldValue('query', ''),
          useCustomPrompt
        })
      }
    >
      <Form>
        <ChatForm
          messages={messages}
          isLoading={isLoading}
          placeholder={placeholder}
          questions={questions}
          createMessage={createMessage}
          resetMessages={resetMessages}
          cardProps={cardProps}
          ref={ref}
        />
      </Form>
    </Formik>
  );
};
