import React from 'react';
import { Menu, MenuItem, ButtonGroup, Popover, Button } from '@blueprintjs/core';

import { useCreateCustomQuoteMutation } from '@/api/customQuotesApi';
import { useGetCustomQuoteTemplatesForOrgQuery } from '@/api/customQuoteTemplatesApi';
import { useFeatureFlag } from '@/app/hooks/useFeatureFlag';
import { successToast, errorToast } from '@/app/lib/toaster';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { govlyTableCSVExport } from '@/app/molecules/GovlyTable/govlyTableCSVExport';
import { GovlyTableCSVExportButton } from '@/app/molecules/GovlyTable/GovlyTableCSVExportButton';
import { QuoteShow } from '@/types/__generated__/GovlyApi';
import { Loading } from '@/app/atoms/Loading/Loading';

import { useOppWorkspaceStoreRef } from '../OppWorkspacePageContents/useOppWorkspaceStore';

type OppWorkspaceQuoteDrawerExportButtonProps = {
  organizationId: string;
  workspaceId: string;
  quote: QuoteShow;
  csvFilename: string;
};

export const OppWorkspaceQuoteDrawerExportButton = ({
  quote,
  organizationId,
  workspaceId,
  csvFilename
}: OppWorkspaceQuoteDrawerExportButtonProps) => {
  const storeRef = useOppWorkspaceStoreRef();
  const { data: templates } = useGetCustomQuoteTemplatesForOrgQuery({ organizationId });
  const [createCustomQuote, _] = useCreateCustomQuoteMutation();

  const hasCustomQuoting = useFeatureFlag('quote-templating');
  const canExportTemplates = quote?.llmExtractStatus === 'completed';

  const { table, isLoading } = useGovlyTableContext();
  if (isLoading) return <Loading />;

  const exportWithTemplate = async (customQuoteTemplateId: string) => {
    try {
      await createCustomQuote({ workspaceId, customQuoteTemplateId, includedQuoteIds: [quote.id] });

      storeRef.setState({ shouldPollCustomQuotes: true });
      successToast('Drafted a custom quote in the workspace!');
    } catch (e) {
      errorToast("Couldn't create exported quote!");
    }
  };

  const menu = (
    <Menu>
      <MenuItem text="Download CSV" onClick={() => govlyTableCSVExport(table, csvFilename)} />
      {canExportTemplates
        ? templates?.map(t => (
            <MenuItem key={t.id} text={`Export as ${t.name} (XLSX)`} onClick={() => exportWithTemplate(t.id)} />
          ))
        : undefined}
    </Menu>
  );

  return (
    <ButtonGroup>
      <GovlyTableCSVExportButton fill={true} filename={csvFilename} />
      {hasCustomQuoting && (
        <Popover content={menu} placement="bottom-start">
          <Button outlined icon="caret-down" aria-label="More" />
        </Popover>
      )}
    </ButtonGroup>
  );
};
