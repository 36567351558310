import { match, P } from 'ts-pattern';
import { partition } from 'lodash-es';

import { formErrorToast, successToast } from '@/app/lib/toaster';
import { useEventTracking } from '@/app/hooks/useEventTracking';
import { WorkspaceBase } from '@/types/__generated__/GovlyApi';
import {
  CreateWorkspace,
  LimitedFollow,
  UpdateWorkspace,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation
} from '@/api/workspacesApi';
import { useCurrentUserAttribute } from '@/api/currentUserApi';

export type UseSubmitWorkspaceFormArgs<Values extends Record<string, unknown>> = {
  follows: LimitedFollow[];
  trackingData?: Record<string, unknown>;
  isOptimistic?: boolean;
} & Partial<Pick<WorkspaceBase, 'workableType'>> & {
    shouldTrack?: boolean;
    onSuccess?: (workspace: UpdateWorkspace['params'] | CreateWorkspace['params']) => void;
    onError?: (args: { error: unknown; formValues: Values }) => void;
  };

export const useSubmitWorkspaceForm = <Values extends Record<string, unknown>>({
  shouldTrack = true,
  onSuccess,
  onError,
  workableType,
  follows,
  trackingData,
  isOptimistic = false
}: UseSubmitWorkspaceFormArgs<Values>) => {
  const { trackEvent } = useEventTracking();
  const [updateWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation();
  const [createWorkspace, { isLoading: isCreating }] = useCreateWorkspaceMutation();
  const currentOrgId = useCurrentUserAttribute('organizationId');

  const onSubmit = async ({
    __formValues: formValues,
    ...payload
  }: (CreateWorkspace['params'] | UpdateWorkspace['params']) & { __formValues: Values }) => {
    try {
      let hasHandledSuccess = false;
      if (isOptimistic && 'id' in payload) {
        hasHandledSuccess = true;
        if (onSuccess) {
          onSuccess(payload);
        } else {
          successToast('Workpsace updated');
        }
      }

      const data = await match(payload)
        .with({ id: P.nonNullable }, vals => updateWorkspace(vals).unwrap())
        .otherwise(vals => createWorkspace(vals).unwrap());

      const { followsAttributes, ...remainingData } = payload;

      if (shouldTrack) {
        const [teamFollows, partnerFollows] = partition(
          follows.filter(({ state }) => state === 'following'),
          ({ organizationUser }) => organizationUser?.organizationId === currentOrgId
        );

        trackEvent({
          object: `${workableType?.toLowerCase() || 'opp'}_workspace` as Lowercase<string>,
          action: 'updated',
          properties: {
            workspaceId: 'id' in remainingData ? remainingData.id : undefined,
            ...remainingData,
            ...data,
            ...trackingData,
            follows: followsAttributes?.map(f => f.email),
            teamFollows: teamFollows?.map(f => f.organizationUser?.email).filter(Boolean),
            partnerFollows: partnerFollows?.map(f => f.organizationUser?.email).filter(Boolean)
          }
        });
      }

      if (hasHandledSuccess) return;

      if (onSuccess) {
        onSuccess(payload);
      } else {
        successToast('id' in payload ? 'Workspace updated' : 'Workspace created');
      }
    } catch (e) {
      if (onError) {
        onError({ error: e, formValues });
      } else {
        formErrorToast(400);
      }
    }
  };

  return { onSubmit, isSubmitting: isUpdating || isCreating };
};
