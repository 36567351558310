import { trackEvent } from '@/app/lib/trackEvent';
import { Workspace, WorkspaceColumnValue, WorkspaceShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';
import { worklistApi } from './worklistApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Workspace', 'Opp', 'Worklist', 'WorkspaceColumnValues'] });

type GetOppWorkspace = {
  params: { id: string; fromSearch?: boolean };
  result: WorkspaceShow;
};

type GetOppWorkspaceColumnValues = {
  params: { id: string };
  result: WorkspaceColumnValue[];
};

type BulkUpdateOppWorkspaces = {
  params: {
    removeFromWorklist?: boolean;
    unfollow?: boolean;
    ids: string[];
  };
  result: void;
};

type AddWorkspaceInteraction = {
  params: {
    id: string; // maps to param oppWorkspaceId
  };
  result: Workspace;
};

export const oppWorkspacesApi = api.injectEndpoints({
  endpoints: build => ({
    getOppWorkspace: build.query<GetOppWorkspace['result'], GetOppWorkspace['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/opp_workspaces/${id}`, params: { ...params, view: 'show' } }),
      providesTags: (result, _err, { id }) => [
        { type: 'Workspace', id },
        { type: 'Opp', id: result?.workableId }
      ]
    }),

    getOppWorkspaceColumnValues: build.query<
      GetOppWorkspaceColumnValues['result'],
      GetOppWorkspaceColumnValues['params']
    >({
      query: ({ id }) => ({ url: `/v2/opp_workspaces/${id}/column_values` }),
      providesTags: (_result, _err, { id }) => [{ type: 'WorkspaceColumnValues', id }]
    }),

    bulkUpdateOppWorkspaces: build.mutation<BulkUpdateOppWorkspaces['result'], BulkUpdateOppWorkspaces['params']>({
      query: body => ({
        url: `/v2/opp_workspaces/bulk_update`,
        method: 'POST',
        body
      }),
      async onQueryStarted({ ids, removeFromWorklist, unfollow }, { dispatch, getState }) {
        try {
          trackEvent({
            object: 'worklist',
            action: 'updated',
            properties: { removeFromWorklist, unfollow, ids }
          });

          for (const { endpointName, originalArgs } of worklistApi.util.selectInvalidatedBy(getState(), [
            { type: 'WorklistSearch' },
            { type: 'Worklist' }
          ])) {
            if (!(endpointName === 'worklistSearch' || endpointName === 'getWorklist')) continue;

            dispatch(
              worklistApi.util.updateQueryData('worklistSearch', originalArgs, draftWorklist => {
                return {
                  ...draftWorklist,
                  results: draftWorklist.results.filter(({ id }) => !ids.includes(id))
                };
              })
            );
          }
        } catch {}
      },
      invalidatesTags: (_result, _err, { removeFromWorklist }) => (removeFromWorklist === undefined ? ['Worklist'] : [])
    }),

    addWorkspaceInteraction: build.mutation<AddWorkspaceInteraction['result'], AddWorkspaceInteraction['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/opp_workspaces/${id}/opp_workspace_interactions`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetOppWorkspaceQuery,
  useBulkUpdateOppWorkspacesMutation,
  useAddWorkspaceInteractionMutation,
  useGetOppWorkspaceColumnValuesQuery
} = oppWorkspacesApi;
