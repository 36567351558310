import { Intent } from '@blueprintjs/core';
import { formatDistance } from 'date-fns';
import { match } from 'ts-pattern';

import { AwardShow } from '@/types/__generated__/GovlyApi';

export type AwardStatus = 'Open' | 'Completed' | 'Not Started' | 'In Progress';

export const getAwardStatus = ({ award }: { award: AwardShow }) => {
  const now = new Date();
  const startDate = award.periodOfPerformanceStartDate ? new Date(award.periodOfPerformanceStartDate) : undefined;
  const endDate = award.periodOfPerformanceEndDate ? new Date(award.periodOfPerformanceEndDate) : undefined;

  const endDate2 = award.orderingPeriodEndDate ? new Date(award.orderingPeriodEndDate) : undefined;

  return match<
    typeof award & { startDate?: Date; endDate?: Date; endDate2?: Date },
    { status?: AwardStatus; statusDetail?: string; intent?: Intent }
  >({
    ...award,
    startDate,
    endDate,
    endDate2
  })
    .when(
      ({ endDate: d }) => d && d < now,
      ({ endDate }) => ({
        status: 'Completed',
        statusDetail: `(${formatDistance(now, endDate as Date)} ago)`,
        intent: 'primary'
      })
    )
    .when(
      ({ startDate: d }) => d && d > now,
      () => ({
        status: 'Not Started',
        statusDetail: `(starts in ${formatDistance(startDate as Date, now)})`,
        intent: 'danger'
      })
    )
    .when(
      ({ endDate }) => endDate && now < endDate,
      ({ endDate }) => ({
        status: 'In Progress',
        statusDetail: `(${formatDistance(endDate as Date, now)} remaining)`,
        intent: 'success'
      })
    )
    .when(
      ({ startDate: d }) => d && d < now,
      ({ endDate2: d }) => ({
        status: 'Open',
        ...(d ? { statusDetail: `(${formatDistance(now, d as Date)} remaining)` } : {}),
        intent: 'warning'
      })
    )
    .otherwise(() => ({}));
};
