import {
  GovernmentEntityShow,
  GovernmentEntityIndex,
  GovernmentEntityTypeaheadOpps,
  PaginationMeta
} from '@/types/__generated__/GovlyApi';

import { topoSortGovernmentEntities } from './governmentEntitiesUtils';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['GovernmentEntity'] });

type GetGovernmentEntities = {
  params: { per?: number; page?: number; search?: string };
  result: {
    results: GovernmentEntityIndex[];
    meta: PaginationMeta;
  };
};

type GetGovernmentEntity = {
  params: { id: string };
  result: GovernmentEntityShow;
};

type UpdateGovernmentEntity = {
  params: Partial<Omit<GovernmentEntityShow, '__typename'>>;
  result: GovernmentEntityShow;
};

type GovernmentEntityTypeahead = {
  params: { query: string; view?: 'opps' };
  response: { results: GovernmentEntityTypeaheadOpps[] };
  result: GovernmentEntityTypeaheadOpps[];
};

export const governmentEntitiesApi = api.injectEndpoints({
  endpoints: build => ({
    getGovernmentEntities: build.query<GetGovernmentEntities['result'], GetGovernmentEntities['params']>({
      query: params => ({ url: `/v2/government_entities`, params }),
      providesTags: ['GovernmentEntity']
    }),

    getGovernmentEntity: build.query<GetGovernmentEntity['result'], GetGovernmentEntity['params']>({
      query: ({ id }) => ({ url: `/v2/government_entities/${id}` }),
      providesTags: (result, err, { id }) => [{ type: 'GovernmentEntity', id }]
    }),

    updateGovernmentEntity: build.mutation<UpdateGovernmentEntity['result'], UpdateGovernmentEntity['params']>({
      query: ({ id, ...body }) => ({ url: `/v2/government_entities/${id}`, method: 'PATCH', body }),
      invalidatesTags: (result, error, arg) => [{ type: 'GovernmentEntity', id: arg.id }]
    }),

    governmentEntityTypeahead: build.query<GovernmentEntityTypeahead['result'], GovernmentEntityTypeahead['params']>({
      query: params => ({
        url: `/v2/government_entities/typeahead`,
        params
      }),
      transformResponse: (response: GovernmentEntityTypeahead['response'], _meta, _arg) =>
        topoSortGovernmentEntities(response.results || [])
    })
  })
});

export const {
  useGetGovernmentEntitiesQuery,
  useGetGovernmentEntityQuery,
  useUpdateGovernmentEntityMutation,
  useGovernmentEntityTypeaheadQuery
} = governmentEntitiesApi;
