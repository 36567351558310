import React from 'react';

import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { AwardDetails } from '@/app/organisms/AwardDetails/AwardDetailsV2';
import { AwardDetailsAdditional } from '@/app/organisms/AwardDetailsV2/AwardDetailsAdditional';
import { useGetAwardsQuery } from '@/api/awardsApiV2';
import { WorkableDetailsActions } from '@/app/organisms/WorkableDetailsActions/WorkableDetailsActions';
import { AwardIndex } from '@/types/__generated__/GovlyApi';
import { useMetaTags } from '@/app/hooks/useMetaTags';

export type AwardDetailsOverviewContentProps = {
  uniqueKey: string;
  className?: string;
};

export const AwardDetailsOverviewContent = ({ uniqueKey }: AwardDetailsOverviewContentProps) => {
  const { indexAward = {} as AwardIndex } = useGetAwardsQuery(
    { uniqueKeys: [uniqueKey], searchType: 'fed' },
    {
      selectFromResult: ({ data = [] }) => ({
        indexAward: data.find(({ uniqueKey: awardUniqueKey }) => awardUniqueKey === uniqueKey)
      })
    }
  );

  useMetaTags(
    {
      title: indexAward?.displayName ? indexAward?.displayName : 'Award Details'
    },
    [indexAward]
  );

  return (
    <>
      <MainColumn className="lg:hidden">
        <WorkableDetailsActions resource={indexAward} />
        <AwardDetails uniqueKey={uniqueKey} />
        <AwardDetailsAdditional uniqueKey={uniqueKey} />
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block">
        <AwardDetails uniqueKey={uniqueKey} />
      </MainColumn>

      <MainColumn columnSpan={4} className="hidden lg:block">
        <WorkableDetailsActions resource={indexAward} />
        <AwardDetailsAdditional uniqueKey={uniqueKey} />
      </MainColumn>
    </>
  );
};
