import { LlmAssistantThreadIndex, LlmAssistantThreadShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['WorkspaceAssistantThreads', 'WorkspaceAssistantThread'] });

type GetWorkspaceAssistantThreads = {
  params: { workspaceId: string };
  result: LlmAssistantThreadIndex[];
};

type GetWorkspaceAssistantThread = {
  params: { workspaceId: string; id: string };
  result: LlmAssistantThreadShow;
};

type CreateWorkspaceAssistantThread = {
  params: { workspaceId: string };
  result: LlmAssistantThreadShow;
};

export const workspaceAssistantThreadsApi = api.injectEndpoints({
  endpoints: build => ({
    getWorkspaceAssistantThreads: build.query<
      GetWorkspaceAssistantThreads['result'],
      GetWorkspaceAssistantThreads['params']
    >({
      query: ({ workspaceId }) => ({
        url: `/v2/llm/workspaces/${workspaceId}/assistant_threads`
      }),
      providesTags: ['WorkspaceAssistantThreads']
    }),

    getWorkspaceAssistantThread: build.query<
      GetWorkspaceAssistantThread['result'],
      GetWorkspaceAssistantThread['params']
    >({
      query: ({ id, workspaceId }) => {
        return {
          url: `/v2/llm/workspaces/${workspaceId}/assistant_threads/${id}`
        };
      },
      providesTags: (r, _err, { workspaceId }) =>
        r ? [{ type: 'WorkspaceAssistantThread', id: r.id, workspaceId }] : []
    }),

    createWorkspaceAssistantThread: build.mutation<
      CreateWorkspaceAssistantThread['result'],
      CreateWorkspaceAssistantThread['params']
    >({
      query: ({ workspaceId }) => ({
        url: `/v2/llm/workspaces/${workspaceId}/assistant_threads`,
        method: 'POST'
      }),
      invalidatesTags: ['WorkspaceAssistantThreads'],
      async onQueryStarted({ workspaceId }, { dispatch, queryFulfilled }) {
        const { data: updatedPost } = await queryFulfilled;
        await dispatch(
          workspaceAssistantThreadsApi.util.upsertQueryData(
            'getWorkspaceAssistantThread',
            { id: updatedPost.id, workspaceId },
            updatedPost
          )
        );
      }
    })
  })
});

export const {
  useGetWorkspaceAssistantThreadsQuery,
  useGetWorkspaceAssistantThreadQuery,
  useCreateWorkspaceAssistantThreadMutation
} = workspaceAssistantThreadsApi;
