import { OutreachRecipientIndex, OutreachRecipientShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

type GetOutreachRecipients = {
  params: {
    limit?: number;
  } | void;
  result: OutreachRecipientIndex[];
};

type GetOutreachRecipient = {
  params: {
    inviteToken: string;
  };
  result: OutreachRecipientShow;
};

type UpdateOutreachRecipient = {
  params: {
    id: string;
    invitedAt?: string;
    invitedToPartnerAt?: string;
  };
  result: OutreachRecipientShow;
};

export const outreachRecipientsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getOutreachRecipients: build.query<GetOutreachRecipients['result'], GetOutreachRecipients['params']>({
      query: params => ({ url: '/v2/outreach_recipients', ...(params ? { params } : {}) })
    }),

    getOutreachRecipientByInviteToken: build.query<GetOutreachRecipient['result'], GetOutreachRecipient['params']>({
      query: ({ inviteToken }) => ({ url: `/v2/outreach_recipients/find`, params: { inviteToken } })
    }),

    updateOutreachRecipient: build.mutation<UpdateOutreachRecipient['result'], UpdateOutreachRecipient['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/outreach_recipients/${id}`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const {
  useGetOutreachRecipientsQuery,
  useGetOutreachRecipientByInviteTokenQuery,
  useUpdateOutreachRecipientMutation
} = outreachRecipientsApi;
