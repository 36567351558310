import {
  SearchQueryMeta,
  TeamingBoardPost,
  TeamingBoardPostFeed,
  TeamingBoardPostIndex,
  WorkspaceOppTeamingBoardPost,
  WorkspaceShow
} from '@/types/__generated__/GovlyApi';
import { TypedQueryDefinitionArgs, useTypedQuery } from '@/app/lib/typeUtils';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['TeamingBoardPosts', 'OppTeamingBoardPostsWorkspaces'] });

export type CreateOppTeamingBoardPost = {
  params: {
    oppId: string;
    subject: string;
    message: string;
  };
  result: TeamingBoardPost;
};

type GetOppTeamingBoardPostsView = 'feed' | 'index';
export type GetOppTeamingBoardPosts<View extends GetOppTeamingBoardPostsView = GetOppTeamingBoardPostsView> = {
  params: {
    oppId: string;
    view: View;
  };
  result: {
    feed: TeamingBoardPostFeed[];
    index: TeamingBoardPostIndex[];
  }[View];
};

type DeleteOppTeamingBoardPost = {
  params: {
    oppId: string;
    postId: string;
  };
  result: void;
};

type CollaborateOppTeamingBoardPost = {
  params: {
    oppId: string;
    postId: string;
  };
  result: WorkspaceShow;
};

type GetOppTeamingBoardPostsWorkspaces = {
  params: {
    oppId: string;
  };
  result: {
    meta: SearchQueryMeta;
    results: WorkspaceOppTeamingBoardPost[];
  };
};

export const oppTeamingBoardPostsApi = api.injectEndpoints({
  endpoints: build => ({
    createOppTeamingBoardPost: build.mutation<CreateOppTeamingBoardPost['result'], CreateOppTeamingBoardPost['params']>(
      {
        query: ({ oppId, ...body }) => ({
          url: `/v2/opps/${oppId}/teaming_board_posts`,
          method: 'POST',
          body
        }),
        invalidatesTags: ['TeamingBoardPosts', 'OppTeamingBoardPostsWorkspaces']
      }
    ),

    getOppTeamingBoardPosts: build.query<GetOppTeamingBoardPosts['result'], GetOppTeamingBoardPosts['params']>({
      query: ({ oppId, ...params }) => ({
        url: `/v2/opps/${oppId}/teaming_board_posts`,
        params
      }),
      providesTags: ['TeamingBoardPosts']
    }),

    deleteOppTeamingBoardPost: build.mutation<DeleteOppTeamingBoardPost['result'], DeleteOppTeamingBoardPost['params']>(
      {
        query: ({ oppId, postId }) => ({
          url: `/v2/opps/${oppId}/teaming_board_posts/${postId}`,
          method: 'DELETE'
        }),
        onQueryStarted: async ({ oppId, postId }, { dispatch, queryFulfilled, getState }) => {
          const results = oppTeamingBoardPostsApi.util
            .selectInvalidatedBy(getState(), [{ type: 'TeamingBoardPosts' }])
            .filter(({ endpointName, originalArgs }) => {
              return endpointName === 'getOppTeamingBoardPosts' && originalArgs.oppId === oppId;
            })
            .map(({ originalArgs }) => {
              return dispatch(
                oppTeamingBoardPostsApi.util.updateQueryData('getOppTeamingBoardPosts', originalArgs, draft => {
                  if (!draft) return;
                  const typedDraft = draft as GetOppTeamingBoardPosts['result'];
                  return typedDraft.filter(post => post.id !== postId) as GetOppTeamingBoardPosts['result'];
                })
              );
            });

          queryFulfilled.catch(() => results.forEach(result => result.undo()));
        }
      }
    ),

    collaborateOppTeamingBoardPost: build.mutation<
      CollaborateOppTeamingBoardPost['result'],
      CollaborateOppTeamingBoardPost['params']
    >({
      query: ({ oppId, postId }) => ({
        url: `/v2/opps/${oppId}/teaming_board_posts/${postId}/collaborate`,
        method: 'POST'
      })
    }),

    getOppTeamingBoardPostsWorkspaces: build.query<
      GetOppTeamingBoardPostsWorkspaces['result'],
      GetOppTeamingBoardPostsWorkspaces['params']
    >({
      query: ({ oppId }) => ({
        url: `/v2/opps/${oppId}/teaming_board_posts/workspaces`
      })
    })
  })
});

export const useGetOppTeamingBoardPostsQuery = <View extends GetOppTeamingBoardPostsView>(
  ...args: TypedQueryDefinitionArgs<GetOppTeamingBoardPosts<View>>
) => {
  return useTypedQuery(oppTeamingBoardPostsApi.useGetOppTeamingBoardPostsQuery, ...args);
};

export const {
  useCreateOppTeamingBoardPostMutation,
  useDeleteOppTeamingBoardPostMutation,
  useCollaborateOppTeamingBoardPostMutation,
  useGetOppTeamingBoardPostsWorkspacesQuery
} = oppTeamingBoardPostsApi;
