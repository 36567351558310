import React, { useState } from 'react';
import { Dialog, Tag } from '@blueprintjs/core';

import { WorkspaceForm } from '@/app/organisms/WorkspaceForm/WorkspaceForm';
import { WorkspaceShow } from '@/types/__generated__/GovlyApi';

export const OppWorkspaceName = ({
  oppId,
  workspace: { id, follows, name, privateAccess, organizationDefault, workflowStage }
}: {
  oppId: string;
  workspace: WorkspaceShow;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex space-x-2">
        <Tag intent="primary">{name}</Tag>
        <Tag round minimal interactive onClick={() => setIsOpen(true)} icon="edit">
          Edit name
        </Tag>
      </div>
      <Dialog onClose={() => setIsOpen(false)} isOpen={isOpen} className="min-w-[50%] p-0">
        <WorkspaceForm
          onCancel={() => setIsOpen(false)}
          onError={() => setIsOpen(true)}
          title="Edit Workspace"
          follows={follows ?? []}
          basicInputs
          followersInputs={false}
          organizationDefault={organizationDefault}
          initialValues={{ id, workableId: oppId, workableType: 'Opp', name, privateAccess, workflowStage }}
          workableTypeForLink="opportunities"
        />
      </Dialog>
    </>
  );
};
