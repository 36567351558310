import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router';

import { openDrawerOrNewTab } from '@/app/lib/navigation';

export const useOpenWorkspace = ({ oppId }: { oppId?: string }) => {
  const [_searchParams, setSearchParams] = useSearchParams();

  const openWorkspace = useCallback(
    (e: React.MouseEvent<HTMLElement>, workspaceId: string) => {
      if (!oppId) return;

      const path = `/opportunities/${oppId}/workspaces/${workspaceId}`;

      openDrawerOrNewTab(e, path, () =>
        setSearchParams({
          drawerType: 'workspaces',
          drawerId: workspaceId
        })
      );
    },
    [oppId, setSearchParams]
  );

  return openWorkspace;
};
