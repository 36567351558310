import { PaginationState, createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useMemo } from 'react';

import { useGetAwardTransactionsQuery } from '@/api/awardsApiV2';
import { asCurrency } from '@/app/lib/numbers';
import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { AwardShow, AwardTransactionIndex } from '@/types/__generated__/GovlyApi';

export type Props = { award: AwardShow };

const columnHelper = createColumnHelper<AwardTransactionIndex>();

const PAGE_SIZE = 5;

export const AwardDetailsTransactionsTable = ({ award }: Props) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: PAGE_SIZE
  });

  const transactionsQuery = useGetAwardTransactionsQuery({
    uniqueKey: award.uniqueKey,
    page: pagination.pageIndex + 1,
    searchType: 'fed'
  });
  const pageMeta = transactionsQuery.data?.meta;

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('modificationNumber', { header: 'Modification Number', size: 56 }),
      columnHelper.accessor('actionDate', { header: 'Action Date' }),
      columnHelper.accessor('obligatedAmount', {
        header: 'Amount Obligated',
        cell: e => asCurrency(e.getValue(), {})
      }),
      columnHelper.accessor('actionType', {
        header: 'Action Type',
        cell: e => <span className="text-wrap">{e.getValue() ?? '(N/A)'}</span>
      })
    ];
  }, []);

  const data = useMemo(() => {
    return transactionsQuery.data?.results ?? [];
  }, [transactionsQuery.data]);

  return (
    <GovlyTable
      id="award_details_transactions_table"
      columns={columns}
      isLoading={transactionsQuery.isLoading}
      loadingRowCount={PAGE_SIZE}
      data={data}
      title="Transaction History"
      manualPagination
      enableSorting={false}
      enableFilters={false}
      rowCount={pageMeta?.total ?? 0}
      onPaginationChange={setPagination}
      state={{ pagination }}
    />
  );
};
