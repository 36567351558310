import React from 'react';

import { Card, CardSection } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { GovernmentEntityLink, GovernmentEntityLogo } from '@/app/organisms/GovernmentEntity/utils';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { GovernmentEntityShow } from '@/types/__generated__/GovlyApi';
import { cn } from '@/app/lib/cn';

type TreeNodeProps = {
  entity: Pick<GovernmentEntityShow, 'id' | 'logoUrl' | 'name'>;
  isCurrentEntity?: boolean;
  hasAncestors?: boolean;
};

const TreeNode = ({ entity, isCurrentEntity, hasAncestors }: TreeNodeProps) => {
  return (
    <li key={entity.id}>
      <div className={cn('flex items-center pb-2', hasAncestors && 'pl-8')}>
        <GovernmentEntityLogo entity={entity} />
        <GovernmentEntityLink
          className={cn(
            'visited:text-black hover:underline',
            isCurrentEntity ? 'font-bold text-primary' : 'text-black'
          )}
          entity={entity}
        />
      </div>
    </li>
  );
};

export const Hierarchy = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity) return null;

  const hasAncestors = (governmentEntity.ancestors?.length ?? 0) > 0;

  return (
    <Card title={'Agency Hierarchy'} titleRenderer={CardHeadingSmall} collapsible={true}>
      <CardSection padded={false} className="p-2 pl-8">
        <ul className="space-y-2">
          {governmentEntity.ancestors?.map(entity => <TreeNode key={entity.id} entity={entity} />)}
          <TreeNode entity={governmentEntity} isCurrentEntity={true} hasAncestors={hasAncestors} />
          {governmentEntity.children?.map(entity => (
            <TreeNode key={entity.id} entity={entity} hasAncestors={hasAncestors} />
          ))}
        </ul>
      </CardSection>
    </Card>
  );
};
