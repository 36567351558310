import React, { useState } from 'react';
import { Menu, MenuItem, Popover, Spinner, Tag } from '@blueprintjs/core';
import { useReward } from 'react-rewards';
import { Formik } from 'formik';

import { workflowStages } from '@/app/lib/workflowStages';
import { useGetOppWorkspaceQuery } from '@/api/oppWorkspacesApi';
import { WorkflowStageTag } from '@/app/molecules/WorkflowStageTag/WorkflowStageTag';
import { WorkspaceShow } from '@/types/__generated__/GovlyApi';
import { useWorkspaceForm } from '@/app/organisms/WorkspaceForm/useWorkspaceForm';
import { Loading } from '@/app/atoms/Loading/Loading';

type OppWorkspaceWorkflowStageProps = {
  workspaceId: string;
  editTag?: React.ReactNode;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'>;

export const OppWorkspaceWorkflowStageTag = ({
  workspaceId,
  editTag = DEFAULT_EDIT_TAG,
  ...rest
}: OppWorkspaceWorkflowStageProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: workspace, isLoading: workspaceLoading } = useGetOppWorkspaceQuery({
    id: workspaceId
  });

  if (workspaceLoading || !workspace) {
    return <Loading type="button"></Loading>;
  }
  const { workflowStage } = workspace;

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={<StageSelect workspace={workspace} onClose={() => setIsOpen(false)} />}
    >
      <div
        id={`workspace-${workspaceId}`}
        className="flex items-center gap-2"
        role="button"
        onClick={() => setIsOpen(b => !b)}
        {...rest}
      >
        <WorkflowStageTag workflowStage={workflowStage} />
        {editTag}
      </div>
    </Popover>
  );
};

const StageSelect = ({ workspace, onClose }: { workspace: WorkspaceShow; onClose: () => void }) => {
  const { follows = [], workflowStage, id, name = '' } = workspace;

  const { reward: submittedReward } = useReward(`workspace-${id}`, 'confetti', {
    lifetime: 50,
    elementCount: 100,
    elementSize: 12,
    decay: 0.97,
    zIndex: 1001
  });

  const { reward: awardedReward } = useReward(`workspace-${id}`, 'emoji', {
    emoji: ['💰', '🤑'],
    lifetime: 50,
    elementCount: 100,
    decay: 0.97,
    zIndex: 1001
  });

  const form = useWorkspaceForm({
    follows,
    workableTypeForLink: 'opportunities',
    initialValues: {
      id,
      workflowStage,
      name,
      workableId: workspace.workableId,
      workableType: 'Opp'
    },
    notifyNewFollowersOnly: false,
    followersInputs: false,
    onCancel: onClose,
    onSuccess: ({ workflowStage }) => {
      if (workflowStage === 'awarded') {
        awardedReward();
      }
      if (workflowStage === 'submitted') {
        submittedReward();
      }

      onClose();
    }
  });

  return (
    <Formik {...form}>
      {({ setFieldValue, submitForm, values, isSubmitting }) => (
        <Menu>
          {workflowStages.map(stage => (
            <MenuItem
              key={stage.value}
              shouldDismissPopover={false}
              text={
                <span className="flex items-center gap-2">
                  {stage.label}
                  {isSubmitting && values.workflowStage === stage.value ? <Spinner size={12} /> : undefined}
                </span>
              }
              roleStructure="listoption"
              selected={workflowStage === stage.value}
              disabled={isSubmitting}
              onClick={async () => {
                await setFieldValue('workflowStage', stage.value);
                await submitForm();
              }}
            />
          ))}
        </Menu>
      )}
    </Formik>
  );
};

const DEFAULT_EDIT_TAG = (
  <Tag round minimal interactive icon="edit">
    Edit stage
  </Tag>
);
