import React, { useEffect } from 'react';
import { match } from 'ts-pattern';

import { Main } from '@/app/atoms/Main/Main';
import { MainColumn } from '@/app/atoms/MainColumn/MainColumn';
import { Loading } from '@/app/atoms/Loading/Loading';
import { useWorkspaceFromSearchDecorator } from '@/api/oppsApi';
import { useGetOppWorkspaceQuery, useAddWorkspaceInteractionMutation } from '@/api/oppWorkspacesApi';
import { OppWorkspaceTimeline } from '@/app/organisms/OppWorkspaceTimeline/OppWorkspaceTimeline';
import { OppWorkspaceCard } from '@/app/organisms/OppWorkspaceCard/OppWorkspaceCard';
import { OppWorkspaceOppDetails } from '@/app/organisms/OppWorkspaceOppDetails/OppWorkspaceOppDetails';
import { AppLoading } from '@/app/atoms/AppLoading/AppLoading';
import { NotFound } from '@/app/molecules/NotFound/NotFound';
import { cn } from '@/app/lib/cn';
import { WorkspaceToolbox } from '@/app/organisms/WorkspaceToolbox/WorkspaceToolbox';

import { OppWorkspaceAssistant } from '../OppWorkspaceAssistant/OppWorkspaceAssistant';

export const OppWorkspacePageContents = ({
  id,
  inDrawer = false,
  tab: currentTab
}: {
  id: string;
  inDrawer?: boolean;
  tab?: string;
}) => {
  const {
    data: fetchedWorkspace,
    isLoading: workspaceLoading,
    isSuccess: workspaceLoaded,
    isError
  } = useGetOppWorkspaceQuery({ id });

  const workspaceFromSearch = useWorkspaceFromSearchDecorator({ id });
  const workspace = workspaceFromSearch || fetchedWorkspace;
  const oppId = workspace?.workableId;

  const [addWorkspaceInteraction] = useAddWorkspaceInteractionMutation();

  useEffect(() => {
    addWorkspaceInteraction({ id });
  }, [addWorkspaceInteraction, id]);

  if (workspaceLoading && !workspace) {
    return <AppLoading />;
  }

  if (isError || !workspace) {
    return <NotFound />;
  }

  const pane = match({ workspaceLoaded, currentTab })
    .with({ workspaceLoaded: true, currentTab: 'Opportunity' }, () => (
      <OppWorkspaceOppDetails workspaceIdProp={workspace.id} oppIdProp={oppId} />
    ))
    .with({ workspaceLoaded: true, currentTab: 'Assistant' }, () => (
      <OppWorkspaceAssistant workspaceIdProp={workspace.id} />
    ))
    .with({ workspaceLoaded: true }, () => (
      <div className="space-y-4 px-4 lg:px-0">
        <WorkspaceToolbox />
        <OppWorkspaceTimeline oppId={oppId} workspaceId={workspace.id} />
      </div>
    ))
    .otherwise(() => <Loading />);

  return (
    <Main className="max-w-full bg-gray-50 relative">
      <MainColumn columnSpan={currentTab === 'Assistant' ? 12 : 8}>{pane}</MainColumn>

      {fetchedWorkspace && currentTab !== 'Assistant' && (
        <MainColumn
          columnSpan={4}
          className={cn(
            'self-start sticky overflow-hidden -m-px p-px',

            inDrawer ? 'top-[calc(var(--top-nav-height)_+_var(--main-padding-y))]' : 'top-[20px]'
          )}
        >
          <OppWorkspaceCard
            workspace={fetchedWorkspace}
            cardListProps={{
              wrapperClassName: cn('overflow-y-auto h-full max-h-[--main-card-full-height]')
            }}
          />
        </MainColumn>
      )}
    </Main>
  );
};
