import { DistributiveOmit } from '@/app/lib/typeUtils';
import { ContentBlock, WorkspaceColumn, WorkspaceColumnValue } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['WorkspaceColumns', 'WorkspaceColumnValues'] });

type UpsertWorkspaceColumnForm = DistributiveOmit<ContentBlock, '__typename' | 'id'> & { id?: string };

export type UpsertWorkspaceColumn = {
  params: UpsertWorkspaceColumnForm;
  result: WorkspaceColumn;
};

export type GetWorkspaceColumns = {
  params: void;
  result: WorkspaceColumn[];
};

type UpdateWorkspaceColumnValueForm = Omit<WorkspaceColumnValue['contentBlock'], '__typename' | 'id'> & {
  id: string | undefined;
};

export type UpdateWorkspaceColumnValue = {
  params: UpdateWorkspaceColumnValueForm & {
    workspaceId: string;
    workspaceColumnId: string;
  };
  result: WorkspaceColumnValue;
};

export type DeleteWorkspaceColumn = {
  params: {
    workspaceColumnId: string;
  };
  result: void;
};

export const workspaceColumnsApi = api.injectEndpoints({
  overrideExisting: process.env.NODE_ENV === 'development',
  endpoints: build => ({
    upsertWorkspaceColumn: build.mutation<UpsertWorkspaceColumn['result'], UpsertWorkspaceColumn['params']>({
      query: ({ id, ...body }) =>
        id
          ? { url: `/v2/workspace_columns/${id}`, body, method: 'PATCH' }
          : { url: '/v2/workspace_columns', body, method: 'POST' },
      invalidatesTags: ['WorkspaceColumns', 'WorkspaceColumnValues']
    }),
    getWorkspaceColumns: build.query<GetWorkspaceColumns['result'], GetWorkspaceColumns['params']>({
      query: () => ({ url: '/v2/workspace_columns' }),
      providesTags: ['WorkspaceColumns']
    }),
    updateWorkspaceColumnValue: build.mutation<
      UpdateWorkspaceColumnValue['result'],
      UpdateWorkspaceColumnValue['params']
    >({
      query: ({ id, workspaceId, ...body }) =>
        id
          ? { url: `/v2/workspaces/${workspaceId}/column_values/${id}`, body, method: 'PATCH' }
          : { url: `/v2/workspaces/${workspaceId}/column_values`, body, method: 'POST' },
      invalidatesTags: ['WorkspaceColumnValues']
    }),
    deleteWorkspaceColumn: build.mutation<DeleteWorkspaceColumn['result'], DeleteWorkspaceColumn['params']>({
      query: ({ workspaceColumnId }) => ({ url: `/v2/workspace_columns/${workspaceColumnId}`, method: 'DELETE' }),
      invalidatesTags: ['WorkspaceColumns', 'WorkspaceColumnValues']
    })
  })
});

export const {
  useUpsertWorkspaceColumnMutation,
  useGetWorkspaceColumnsQuery,
  useUpdateWorkspaceColumnValueMutation,
  useDeleteWorkspaceColumnMutation
} = workspaceColumnsApi;
