import React, { useMemo, useState, useCallback } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Select } from '@blueprintjs/select';
import { Tag, AnchorButton, Button, MenuItem, CompoundTag } from '@blueprintjs/core';
import { Icon } from '@blueprintjs/core';
import { groupBy, maxBy } from 'lodash-es';
import { parseISO } from 'date-fns';
import { match } from 'ts-pattern';

import { GovlyTable } from '@/app/molecules/GovlyTable/GovlyTable';
import { formatTime, DATETIME_24_SHORT } from '@/app/lib/dates';
import { useGetOppAttachmentsQuery } from '@/api/oppsApi';
import { OppAttachmentWithSummary, OppSourceBase } from '@/types/__generated__/GovlyApi';
import { GovlyTableToolbar } from '@/app/molecules/GovlyTable/GovlyTableToolbar';
import { FileViewer } from '@/app/molecules/FileViewer/FileViewer';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { GovlyTableTitle } from '@/app/molecules/GovlyTable/GovlyTableTitle';
import { getFileExtensionIconProps } from '@/app/lib/file';
import { naturalSort } from '@/app/lib/naturalSort';
import { getLatestSequencedNoticeTypes } from '@/app/organisms/OppAttachmentsTable/OppAttachmentsUtils';
import { getNonEmptyString } from '@/app/lib/strings';
import { useCurrentUserAttribute } from '@/api/currentUserApi';

export type OppAttachmentsTableProps = {
  id: string;
} & React.HTMLAttributes<HTMLDivElement>;

const columnHelper = createColumnHelper<OppAttachmentWithSummary>();

const ALL = { label: 'All', value: undefined } as const;
const MOST_UP_TO_DATE = { label: 'Most up to date', value: 'most_up_to_date' } as const;

type NoticeType = { label: string; value: OppSourceBase['noticeType'] | (typeof MOST_UP_TO_DATE)['value'] };

export type SortOption = 'Last Seen' | 'First Seen' | 'Name';
const sortItems: SortOption[] = ['Last Seen', 'First Seen', 'Name'];

export const OppAttachmentsTable = ({ id, ...rest }: OppAttachmentsTableProps) => {
  const query = useGetOppAttachmentsQuery({ id });
  const attachments = useMemo(() => query.data?.results ?? [], [query.data?.results]);
  const [fileToView, setFileToView] = useState<OppAttachmentWithSummary['file'] | null>(null);
  const disableDownloads = useCurrentUserAttribute('organization.settings.disableOppAttachmentDownloads');

  const [noticeType, setNoticeType] = useState<NoticeType>(ALL);
  const noticeTypes = [...new Set([...attachments.flatMap(a => a.noticeTypes)])].filter(nt => nt != null);
  const noticeTypeItems = [ALL, MOST_UP_TO_DATE, ...noticeTypes.sort().map(nt => ({ label: nt, value: nt }))];
  const [sort, setSort] = useState<SortOption>('Last Seen');
  const [desc, setDesc] = useState(true);

  const handleFileClick = useCallback((file: OppAttachmentWithSummary['file']) => {
    setFileToView(file);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('file.name', {
        header: '',
        enableColumnFilter: false,
        cell: ({ row }) => {
          const firstSeen = row.original.postedAts?.[0];
          const lastSeen = row.original.postedAts?.slice().reverse()[0];
          const sequencedNoticeTypes = getLatestSequencedNoticeTypes(row.original.sequencedNoticeTypes);
          const summary = getNonEmptyString(
            row.original.summary?.summary?.replace(/^\{\}$/, ''),
            '(No summary available)'
          );

          return (
            <div className="flex gap-2 items-start">
              <Icon {...getFileExtensionIconProps(row.original.file?.name)} />

              <div className="flex flex-col items-start gap-3">
                <LinkTag
                  tag="button"
                  onClick={() => handleFileClick(row.original.file)}
                  data-testid="file-name"
                  className="font-medium"
                >
                  {row.original.file?.name ?? 'N/A'}
                </LinkTag>

                <div className="flex items-start gap-2">
                  <Icon icon="lightning" className="mt-1" />
                  <span className="text-sm text-gray-600">{summary}</span>
                </div>

                <div className="flex gap-2 flex-wrap">
                  {sequencedNoticeTypes?.map(nt => (
                    <Tag key={nt} minimal intent="success">
                      {nt}
                    </Tag>
                  ))}

                  <CompoundTag leftContent="Created" minimal>
                    {formatTime(row.original.createdAt, DATETIME_24_SHORT)}
                  </CompoundTag>

                  {firstSeen && (
                    <CompoundTag leftContent="First Seen" minimal intent="primary">
                      {formatTime(firstSeen, DATETIME_24_SHORT)}
                    </CompoundTag>
                  )}

                  {lastSeen && (
                    <CompoundTag leftContent="Last Seen" minimal intent="primary">
                      {formatTime(lastSeen, DATETIME_24_SHORT)}
                    </CompoundTag>
                  )}

                  {row.original.warningTags?.map(wt => (
                    <Tag key={wt} minimal intent="warning">
                      {wt}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      })
    ],
    [handleFileClick]
  );

  const filteredAttachments = useMemo(() => {
    const { value } = noticeType;
    return match(value)
      .with(undefined, () => attachments)
      .with('most_up_to_date', () => {
        const grouped = groupBy(attachments, a => a.file?.name);

        const maxCreatedAt = Object.values(grouped)
          .map(group => maxBy(group, a => parseISO(a.createdAt)))
          .filter(x => x != null);

        return maxCreatedAt;
      })
      .otherwise(s => attachments.filter(a => a.noticeTypes?.includes(s)));
  }, [attachments, noticeType]);

  const downloadAllLink = useMemo(() => {
    const attachmentIds = filteredAttachments.map(a => a.id);
    return `/api/v2/opps/${id}/zip?attachment_ids=${attachmentIds.join(',')}`;
  }, [id, filteredAttachments]);

  const sortedAttachments = useMemo(() => {
    return [...filteredAttachments].sort((a, b) => {
      const items = desc ? [b, a] : [a, b];

      const sortedByName = naturalSort(...(items.map(i => i.file?.name || '') as [string, string]));

      switch (sort) {
        case 'Name':
          return sortedByName;
        case 'First Seen': {
          const [a, b] = items.map(i => i.postedAts?.[0] ?? '');
          const firstSeenComparison = a.localeCompare(b);
          return firstSeenComparison === 0 ? sortedByName : firstSeenComparison;
        }
        case 'Last Seen':
        default: {
          const [a, b] = items.map(i => i.postedAts?.slice(-1)[0] ?? '');
          const lastSeenComparison = a.localeCompare(b);
          return lastSeenComparison === 0 ? sortedByName : lastSeenComparison;
        }
      }
    });
  }, [filteredAttachments, sort, desc]);

  return (
    <div {...rest}>
      <GovlyTable
        id="opp_attachments_table"
        columns={columns}
        data={sortedAttachments}
        isFixedLayout
        title={<GovlyTableTitle title="Attachments" />}
        rightElement={
          <GovlyTableToolbar>
            {!disableDownloads && (
              <AnchorButton
                icon="download"
                text="Download All"
                href={downloadAllLink}
                disabled={!filteredAttachments.length}
              />
            )}
            <Select
              items={noticeTypeItems}
              activeItem={noticeType}
              onItemSelect={setNoticeType}
              filterable={false}
              itemRenderer={(item, { modifiers, handleClick, handleFocus }) => (
                <MenuItem
                  text={item.label}
                  key={item.value ?? 'missing'}
                  roleStructure="listoption"
                  selected={noticeType.value === item.value}
                  active={modifiers.active}
                  onClick={handleClick}
                  onFocus={handleFocus}
                />
              )}
            >
              <Button icon="filter" text={noticeType.label} />
            </Select>
            <Select
              items={sortItems}
              activeItem={sort}
              onItemSelect={setSort}
              filterable={false}
              itemRenderer={(item, { modifiers, handleClick, handleFocus }) => (
                <MenuItem
                  text={item}
                  key={item}
                  roleStructure="listoption"
                  selected={sort === item}
                  active={modifiers.active}
                  onClick={handleClick}
                  onFocus={handleFocus}
                />
              )}
            >
              <Button icon="property" text={sort} />
            </Select>
            <Button
              icon={desc === false ? 'sort-asc' : 'sort-desc'}
              aria-label="Sort Order"
              onClick={() => setDesc(prev => !prev)}
            />
          </GovlyTableToolbar>
        }
        isLoading={query.isLoading}
        emptyStateProps={{ icon: 'paperclip', title: 'No attachments' }}
        initialState={{ pagination: { pageSize: 10 } }}
      />
      {fileToView && (
        <FileViewer
          isOpen={true}
          downloadLink={fileToView.link}
          fileName={fileToView.name}
          fileUrl={fileToView.serviceUrl ?? fileToView.link}
          onClose={() => setFileToView(null)}
        />
      )}
    </div>
  );
};
