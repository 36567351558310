import { CustomQuoteTemplateShow } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['CustomQuoteTemplates'] });

type GetTemplatesForOrg = {
  params: { organizationId: string };
  result: CustomQuoteTemplateShow[];
};

type CreateQuoteTemplate = {
  params: {
    organizationId: string | null;
    name: string;
    description: string;
    templateFile: string;
  };
  result: CustomQuoteTemplateShow;
};

type DeleteCustomTemplate = {
  params: { id: string };
  result: void;
};

export const customQuoteTemplatesApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomQuoteTemplatesForOrg: build.query<GetTemplatesForOrg['result'], GetTemplatesForOrg['params']>({
      query: ({ organizationId }) => ({ url: '/v2/custom_quote_templates', params: { organizationId } }),
      providesTags: ['CustomQuoteTemplates']
    }),

    createCustomQuoteTemplate: build.mutation<CreateQuoteTemplate['result'], CreateQuoteTemplate['params']>({
      query: body => ({ url: `/v2/custom_quote_templates`, method: 'POST', body }),
      invalidatesTags: ['CustomQuoteTemplates']
    }),

    deleteCustomQuoteTemplate: build.mutation<DeleteCustomTemplate['result'], DeleteCustomTemplate['params']>({
      query: ({ id }: { id: string }) => ({ url: `/v2/custom_quote_templates/${id}`, method: 'DELETE' }),
      invalidatesTags: ['CustomQuoteTemplates']
    })
  })
});

export const {
  useGetCustomQuoteTemplatesForOrgQuery,
  useDeleteCustomQuoteTemplateMutation,
  useCreateCustomQuoteTemplateMutation
} = customQuoteTemplatesApi;
