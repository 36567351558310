import React from 'react';
import { Button, Icon, Menu, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import { match, P } from 'ts-pattern';

import { CardListItem } from '@/app/molecules/CardList/CardList';
import { CustomQuoteShow as CustomQuote } from '@/types/__generated__/GovlyApi';
import { useCurrentUserAttribute } from '@/api/currentUserApi';
import { useAuthorized } from '@/app/hooks/useAuthorize';
import { useDeleteCustomQuoteMutation } from '@/api/customQuotesApi';
import { errorToast, successToast } from '@/app/lib/toaster';
import { LinkTag } from '@/app/atoms/LinkTag/LinkTag';
import { Text } from '@/app/atoms/Typography/Typography';

import { useOppWorkspaceStoreRef } from '../OppWorkspacePageContents/useOppWorkspaceStore';

type OppWorkspaceCustomQuoteListItemProps = {
  customQuote: CustomQuote;
};

const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function OppWorkspaceCustomQuoteListItem({ customQuote }: OppWorkspaceCustomQuoteListItemProps) {
  const organizationUserId = useCurrentUserAttribute('id');
  const isSysAdmin = useAuthorized({ role: 'sys_admin' });
  const canDelete = isSysAdmin || organizationUserId === customQuote.preparedById;

  const store = useOppWorkspaceStoreRef();
  const [deleteMutation, deleteMeta] = useDeleteCustomQuoteMutation();
  const deleteQuote = async () => {
    try {
      await deleteMutation({ id: customQuote.id });

      store.setState({ shouldPollCustomQuotes: true });
      successToast('Quote deleted!');
    } catch (e) {
      errorToast(e);
    }
  };

  const icon = match(customQuote.status)
    .with('pending', () => <Spinner size={36} intent="primary" />)
    .otherwise(() => <Icon icon="clipboard-file" className="text-green-500" size={36} />);

  const subtext = match(customQuote)
    .with({ status: 'pending' }, () => 'Rendering line items into template ...')
    .with({ lineItemCount: 0 }, () => 'Empty draft quote')
    .with(
      { lineItemTotal: P.not(P.nullish) },
      ({ lineItemTotal, lineItemCount }) => `${lineItemCount} line items totalling ${moneyFormat.format(lineItemTotal)}`
    )
    .otherwise(() => 'No line items found.');

  const link = match(customQuote)
    .with({ resultFile: P.not(P.nullish) }, ({ resultFile }) => (
      <LinkTag
        tag="a"
        className="font-bold block"
        href={resultFile.link}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        {resultFile.name}
      </LinkTag>
    ))
    .otherwise(() => null);

  const menu = (
    <Popover
      interactionKind="click"
      className="max-w-md"
      content={
        <Menu key="menu">
          <MenuItem
            icon="import"
            text="Download"
            href={customQuote.resultFile?.link}
            target="_blank"
            rel="noopener noreferrer nofollow"
          />
          {canDelete ? <MenuItem icon="trash" text="Remove" onClick={_ => deleteQuote()} /> : undefined}
        </Menu>
      }
    >
      <Button icon="more" loading={deleteMeta.isLoading} />
    </Popover>
  );

  return (
    <CardListItem className="flex flex-row gap-x-2 items-center">
      {icon}
      <div className="flex flex-grow flex-col">
        {link}
        <Text>{subtext}</Text>
      </div>
      {menu}
    </CardListItem>
  );
}
