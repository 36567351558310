import React from 'react';
import { NonIdealStateProps } from '@blueprintjs/core';

import { Card, CardBody, CardProps, CardSectionProps } from '@/app/atoms/Card/Card';
import { cn } from '@/app/lib/cn';
import { GovlyTableToolbar } from '@/app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableColumnVisibilityPopover } from '@/app/molecules/GovlyTable/GovlyTableColumnVisibilityPopover';
import { useGovlyTableContext } from '@/app/molecules/GovlyTable/GovlyTableContext';
import { GovlyTablePagination } from '@/app/molecules/GovlyTable/GovlyTablePagination';
import { GovlyTableEmptyState } from '@/app/molecules/GovlyTable/GovlyTableEmptyState';

import { GovlyTableBase } from './GovlyTableBase';

export type GovlyTableCardProps = {
  bodyProps?: Partial<CardSectionProps>;
  cardProps?: Partial<CardProps>;
  emptyStateProps?: NonIdealStateProps;
};

export function GovlyTableCard({ bodyProps, cardProps, emptyStateProps }: GovlyTableCardProps) {
  const { table } = useGovlyTableContext();

  return (
    <Card
      {...cardProps}
      rightElement={
        cardProps?.rightElement ?? (
          <GovlyTableToolbar>
            {table.options.enableHiding !== false ? <GovlyTableColumnVisibilityPopover /> : null}
          </GovlyTableToolbar>
        )
      }
    >
      <CardBody {...bodyProps} className={cn('!p-0', bodyProps?.className)}>
        <div>
          <div className="overflow-x-auto w-full">
            <GovlyTableBase />
          </div>

          <div className="px-5 py-2 border-t border-gray-200 empty:py-0 empty:border-t-0">
            <GovlyTablePagination />
            <GovlyTableEmptyState {...emptyStateProps} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
