import type { Api, RootState } from '@reduxjs/toolkit/query';
import { TypedUseQuery, TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react';
import { ColumnHelper } from '@tanstack/react-table';

import { BaseQueryFn } from '@/api/baseQuery';

export type ValueOrTransformer<T extends object | Array<unknown>> =
  T extends Array<infer Item> ? Array<Item> | ((arr: Array<Item>) => Array<Item>) : Partial<T> | ((obj: T) => T);

export const mergePartial = <T extends object | Array<unknown>>(base: T, partial?: ValueOrTransformer<T>): T => {
  return typeof partial === 'function'
    ? partial(base as unknown[])
    : Array.isArray(base) && Array.isArray(partial)
      ? ([...base, ...partial] as T)
      : ({ ...base, ...partial } as T);
};

/**
 * Useful for typing result of Object.entries()
 */
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/** Supports dot notation for keys */
export type GetPathValue<T, K extends string> = K extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? GetPathValue<T[Key], Rest>
    : undefined
  : K extends keyof T
    ? T[K]
    : undefined;

/**
 * Standard Omit causes union types to collapse into a partial intersection type.
 *
 * tl;dr the `extends infer` is a ts hack to "iterate" on union type members. So each member of `Union` gets passed as `Member`
 * e.g.,
 * ```ts
 * type A = { id: string, type: "a", uniqueA: number }
 * type B = { id: string, type: "b", uniqueB: string }
 *
 * type UndistributiveResult = Omit<A | B, 'id'> // { type: "a" | "b" }
 * type DistributiveResult = DistributiveOmit<A | B, 'id'> // { type: "a", uniqueA: number } | { type: "b", uniqueB: string }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DistributiveOmit<Union, Key extends keyof any> = Union extends infer Member ? Omit<Member, Key> : never;

/* eslint-disable @typescript-eslint/no-explicit-any */
export type GetApiSliceRootState<ApiSlice extends Api<any, any, any, any, any>> =
  ApiSlice extends Api<any, infer Endpoints, infer ReducerPath, infer TagTypes, any>
    ? RootState<Endpoints, TagTypes, ReducerPath>
    : never;
/* eslint-enable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ColumnHelperAccessor<helper extends ColumnHelper<any>> = ReturnType<helper['accessor']>;
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Since RTK Query takes the result first, we can't use generics to determine the result type.
 * This is a wrapper that allows you to pass your own generic types to have a better typing experience with things like api `view` params.
 *
 * Example:
 * ```ts
 * type View = "initials" | "full"
 *
 * type GetAvatar = {
 *   params: {
 *     view: View
 *   }
 *   result: {
 *     initials:  { initials: string }
 *     full: { thumbUrl: string }
 *   }[View]
 * }
 *
 * export const useGetAvatar = <view extends View>(
 *   ...args: TypedQueryDefinitionArgs<GetAvatar<View>>
 * ) => {
 *   return useTypedQuery(avatarsApi.useGetAvatarQuery, ...args);
 * };
 * // ...
 * const { data } = useGetAvatar({ view: 'initials' });
 * data.initials // type safe!
 * const { data: fullData } = useGetAvatar({ view: 'full' });
 * fullData.thumbUrl // type safe!
 * ```
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function useTypedQuery<Args extends Parameters<TypedUseQuery<any, any, BaseQueryFn>>>(
  hook: TypedUseQuery<any, any, BaseQueryFn>,
  ...args: Args
) {
  // @ts-expect-error doesn't interpret `Parameters` as a tuple for some reason?
  return hook(...args) as ArgsToHookResult<Args>;
}

export type TypedQueryDefinitionArgs<Shape extends { params: any; result: any }> = Parameters<
  TypedUseQuery<Shape['result'], Shape['params'], BaseQueryFn>
>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export type ArgsToHookResult<Args> =
  Args extends Parameters<TypedUseQuery<infer Result, infer Params, infer QueryFn>>
    ? TypedUseQueryHookResult<Result, Params, QueryFn>
    : never;
