import { create } from 'zustand';
import { useEffect, useRef } from 'react';

import { createSelectors } from '@/app/lib/createSelectors';

type Store = {
  failedFormValuesByWorkspaceId: Record<string, unknown>;
};

export type { Store as WorkableFormStore };

export { useStore as useWorkableFormStore };
const useStore = createSelectors(
  create<Store>(() => ({
    failedFormValuesByWorkspaceId: {}
  }))
);

export const setFailedFormValuesByWorkspaceId = (workspaceId: string, formValues: unknown) =>
  useStore.setState(state => ({
    failedFormValuesByWorkspaceId: {
      ...state.failedFormValuesByWorkspaceId,
      [workspaceId]: formValues
    }
  }));

export const getFailedFormValuesByWorkspaceId = <FormValues extends Record<string, unknown>>(workspaceId: string) =>
  useStore.getState().failedFormValuesByWorkspaceId[workspaceId] as FormValues | undefined;

export const clearFailedFormValuesByWorkspaceId = (workspaceId: string) =>
  useStore.setState(current => {
    const { [workspaceId]: _, ...failedFormValuesByWorkspaceId } = current.failedFormValuesByWorkspaceId;
    return { failedFormValuesByWorkspaceId };
  });

export const useFailedFormValuesByWorkspaceId = <FormValues extends Record<string, unknown>>(workspaceId?: string) => {
  const failedFormValuesRef = useRef(workspaceId ? getFailedFormValuesByWorkspaceId<FormValues>(workspaceId) : {});
  useEffect(() => {
    if (!workspaceId) return;
    clearFailedFormValuesByWorkspaceId(workspaceId);
  }, [workspaceId]);

  return failedFormValuesRef.current;
};
