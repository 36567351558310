import { keyBy } from 'lodash-es';
import { match, P } from 'ts-pattern';
import { TypedMutationOnQueryStarted } from '@reduxjs/toolkit/query';
import { Option } from 'space-monad';

import {
  FollowBase,
  FollowDisplayable,
  OrganizationUserFollowerSelectInput,
  WorkspaceBase,
  WorkspaceShow
} from '@/types/__generated__/GovlyApi';
import { oppWorkspacesApi } from '@/api/oppWorkspacesApi';
import { OrganizationUsersApi } from '@/api/organizationUsersApi';
import { GetApiSliceRootState } from '@/app/lib/typeUtils';
import { oppsApi } from '@/api/oppsApi';
import { BaseQueryFn } from '@/api/baseQuery';
import { awardsApi } from '@/api/awardsApi';
import { contactsApi } from '@/api/contactsApi';
import { awardsApi as awardsApiV2 } from '@/api/awardsApiV2';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Workspace', 'Opp', 'Contact', 'USASpendingAward', 'Award'] });

export type LimitedFollow = Pick<FollowBase, 'organizationUserId' | 'state' | 'notifications'> & {
  organizationUser?: { organizationId: string } & Partial<
    Pick<OrganizationUserFollowerSelectInput, 'avatar' | 'email' | 'initials' | 'name'>
  >;
  id?: string;
};
export type FollowsAttributes = Omit<LimitedFollow, 'organizationUser'> & { email?: string };

export type CreateWorkspace = {
  params: {
    notifyIds?: string[];
    followsAttributes?: FollowsAttributes[];
    subject?: string;
    customMessage?: string;
  } & Partial<
    Pick<
      WorkspaceBase,
      'name' | 'privateAccess' | 'workflowStage' | 'workableId' | 'workableType' | 'organizationDefault' | 'archivedAt'
    >
  >;
  result: WorkspaceBase;
};

export type UpdateWorkspace = {
  params: { id: string } & CreateWorkspace['params'];
  result: WorkspaceBase;
};

export const workspacesApi = api.injectEndpoints({
  endpoints: build => ({
    createWorkspace: build.mutation<CreateWorkspace['result'], CreateWorkspace['params']>({
      query: body => ({
        url: `/v2/workspaces`,
        method: 'POST',
        body
      }),
      invalidatesTags: result =>
        result?.workableType === 'Opp'
          ? [
              { type: 'Workspace', id: result.id },
              { type: 'Opp', id: result.workableId }
            ]
          : result?.workableType
            ? [result?.workableType]
            : ['Workspace']
    }),

    updateWorkspace: build.mutation<UpdateWorkspace['result'], UpdateWorkspace['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/workspaces/${id}`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: onUpdateStarted
    })
  })
});

export const { useCreateWorkspaceMutation, useUpdateWorkspaceMutation } = workspacesApi;

type OnUpdateStarted = TypedMutationOnQueryStarted<UpdateWorkspace['result'], UpdateWorkspace['params'], BaseQueryFn>;
type OnUpdateStartedArgs = Parameters<NonNullable<OnUpdateStarted>>;

export async function onUpdateStarted(
  {
    name,
    id,
    workflowStage,
    privateAccess,
    archivedAt,
    followsAttributes,
    workableId,
    workableType
  }: OnUpdateStartedArgs[0],
  { queryFulfilled, dispatch, getState }: OnUpdateStartedArgs[1]
) {
  const state = getState() as GetApiSliceRootState<typeof OrganizationUsersApi>;

  const orgUserMap = keyBy(
    OrganizationUsersApi.util
      .selectInvalidatedBy(state, ['OrganizationUser'])
      .filter(
        ({ endpointName, originalArgs }) =>
          endpointName === 'getOrganizationUsers' && originalArgs.view === 'follower_select_input'
      )
      .map(
        ({ endpointName, originalArgs }) =>
          OrganizationUsersApi.endpoints[endpointName as 'getOrganizationUsers'].select(originalArgs)(state).data ?? []
      )
      .flat() as OrganizationUserFollowerSelectInput[],
    x => x.id
  );

  const follows: FollowDisplayable[] | undefined = followsAttributes?.map((f, i) => {
    const { __typename: _, ...orgUser } = orgUserMap[f.organizationUserId];
    return {
      __typename: 'FollowDisplayable',
      id: f.id ?? `missing_id_${i}`,
      active: f.state === 'following',
      inactive: f.state !== 'following',
      notifications: f.notifications,
      createdAt: new Date().toISOString(),
      organizationUserId: f.organizationUserId,
      state: f.state,
      targetId: id as string,
      targetType: 'Workspace',
      stateInteger: f.state === 'following' ? 1 : 0,
      updatedAt: new Date().toISOString(),
      organizationUser: { __typename: 'OrganizationUserFollow', ...orgUser }
    };
  });

  const draft = Object.fromEntries(
    Object.entries({ name, workflowStage, privateAccess, archivedAt, follows }).filter(([_k, v]) => v != null)
  );

  const setDraft = <T extends Partial<WorkspaceShow>>(draft: T) => {
    const results = match(workableType)
      .with('Opp', () => [
        dispatch(
          oppWorkspacesApi.util.updateQueryData('getOppWorkspace', { id }, current => {
            Object.assign(current, draft);
          })
        ),
        ...oppsApi.util
          .selectInvalidatedBy(state, ['Opp'])
          .filter(({ endpointName, originalArgs }) => {
            return match({ endpointName, originalArgs })
              .with({ endpointName: 'getOpp', originalArgs: { id: workableId } }, () => true)
              .with({ endpointName: 'getOpps', originalArgs: { ids: P.array(P.string) } }, ({ originalArgs }) =>
                originalArgs.ids.includes(workableId ?? '')
              )
              .otherwise(() => false);
          })
          .map(({ endpointName, originalArgs }) => {
            const safeName = endpointName as 'getOpp' | 'getOpps';
            return dispatch(
              oppsApi.util.updateQueryData(safeName, originalArgs, current => {
                const entryArray = Array.isArray(current) ? current : [current];

                entryArray.forEach(opp => {
                  if (opp.id === workableId) {
                    opp.workspaces.forEach(workspace => {
                      if (workspace.id === id) {
                        Object.assign(workspace, draft);
                      }
                    });
                  }
                });
              })
            );
          })
      ])
      .with('Award', () => [
        ...awardsApiV2.util
          .selectInvalidatedBy(state, ['Award'])
          .filter(({ endpointName, originalArgs }) => {
            return match({ endpointName, originalArgs })
              .with(
                { endpointName: 'getAwardsV2', originalArgs: { uniqueKeys: P.array(P.string) } },
                ({ originalArgs }) => originalArgs.uniqueKeys.includes(workableId ?? '')
              )
              .otherwise(() => false);
          })
          .map(({ endpointName, originalArgs }) => {
            const safeName = endpointName as 'getAwardsV2';
            return dispatch(
              awardsApiV2.util.updateQueryData(safeName, originalArgs, current => {
                current.forEach(award => {
                  if (award.id === workableId && award.workspace?.id === id) {
                    Object.assign(award.workspace, draft);
                  }
                });
              })
            );
          })
      ])
      .with('USASpendingAward', () => [
        ...awardsApi.util
          .selectInvalidatedBy(state, ['USASpendingAward'])
          .filter(({ endpointName, originalArgs }) => {
            return match({ endpointName, originalArgs })
              .with({ endpointName: 'getAwards', originalArgs: { ids: P.array(P.string) } }, ({ originalArgs }) =>
                originalArgs.ids.includes(workableId ?? '')
              )
              .otherwise(() => false);
          })
          .map(({ endpointName, originalArgs }) => {
            const safeName = endpointName as 'getAwards';
            return dispatch(
              awardsApi.util.updateQueryData(safeName, originalArgs, current => {
                current.forEach(award => {
                  if (award.id === workableId && award.workspace?.id === id) {
                    Object.assign(award.workspace, draft);
                  }
                });
              })
            );
          })
      ])
      .with('Contact', () => [
        ...contactsApi.util
          .selectInvalidatedBy(state, ['Contact'])
          .filter(({ endpointName, originalArgs }) => {
            return match({ endpointName, originalArgs })
              .with({ endpointName: 'getContacts', originalArgs: { ids: P.array(P.string) } }, ({ originalArgs }) =>
                originalArgs.ids.includes(workableId ?? '')
              )
              .otherwise(() => false);
          })
          .map(({ endpointName, originalArgs }) => {
            const safeName = endpointName as 'getContacts';
            return dispatch(
              contactsApi.util.updateQueryData(safeName, originalArgs, current => {
                current.forEach(contact => {
                  if (contact.id === workableId && contact.workspace?.id === id) {
                    Object.assign(contact.workspace, draft);
                  }
                });
              })
            );
          })
      ])
      .otherwise(() => []);

    return results;
  };

  const results = setDraft(draft);

  await queryFulfilled
    .then(result => {
      if (!follows) return;
      const followsByEmail = keyBy(result.data.follows, f => f.email);

      const updatedFollows = follows.map(f => {
        const id = Option(f.organizationUser?.email)
          .map(email => followsByEmail[email]?.id)
          .getOrElse(f.id);

        return { ...f, id };
      });

      setDraft({ ...draft, follows: updatedFollows });
    })
    .catch(() => {
      if (follows?.some(f => f.id?.includes('missing_id'))) {
        match(workableType)
          .with('Opp', () => {
            dispatch(oppsApi.util.invalidateTags([{ type: 'Opp', id: workableId }]));
            dispatch(oppWorkspacesApi.util.invalidateTags([{ type: 'Workspace', id }]));
          })
          .with('Award', () => {
            dispatch(awardsApiV2.util.invalidateTags([{ type: 'Award', id: workableId }]));
          })
          .with('USASpendingAward', () => {
            dispatch(awardsApi.util.invalidateTags([{ type: 'USASpendingAward', id: workableId }]));
          })
          .with('Contact', () => {
            dispatch(contactsApi.util.invalidateTags([{ type: 'Contact', id: workableId }]));
          })
          .otherwise(() => {});
        return;
      }

      results.forEach(r => {
        r.undo();
      });
    });
}
