import { Reaction } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Reactions'] });

const invalidatesTags = <Arg extends Pick<Reaction, 'reactableId'>>(_result: unknown, _err: unknown, arg?: Arg) =>
  arg?.reactableId ? [{ type: 'Reactions' as const, id: arg.reactableId }] : [];

type GetReactions = {
  params: {
    reactableId: string;
  };
  result: Reaction[];
};

type CreateReaction = {
  params: Pick<Reaction, 'name' | 'reactableId' | 'reactableType'>;
  result: Reaction;
};

export const reactionsApi = api.injectEndpoints({
  endpoints: build => ({
    getReactions: build.query<GetReactions['result'], GetReactions['params']>({
      query: ({ reactableId }) => ({ url: `/v2/reactions`, params: { reactableId } }),
      providesTags: invalidatesTags
    }),
    createReaction: build.mutation<CreateReaction['result'], CreateReaction['params']>({
      query: ({ ...body }) => ({
        url: `/v2/reactions`,
        method: 'POST',
        body
      }),
      invalidatesTags
    })
  })
});

export const { useGetReactionsQuery, useCreateReactionMutation } = reactionsApi;
