import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';

export const BudgetRequestsChart = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  const options: Highcharts.Options = useMemo(() => {
    if (!governmentEntity?.latestUpload?.budgetaryRequests) return {};

    const data = governmentEntity?.latestUpload?.budgetaryRequests
      .map(request => ({ name: request.name, y: request.amount, text: request.summary }))
      .filter(({ name, y }) => !name.match(/reduction/i) && y && y !== 0)
      .sort((a, b) => (b['y'] || 0) - (a['y'] || 0));

    if (data.length === 0) return {};

    return {
      plotOptions: {
        bar: {
          colorByPoint: true
        },
        column: {
          colorByPoint: true
        }
      },
      title: {
        text: undefined
      },
      chart: {
        type: 'bar',
        height: 350
      },
      legend: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: undefined
        }
      },
      lang: {
        numericSymbols: ['Th', 'M', 'B', 'T']
      },
      tooltip: {
        valuePrefix: '$',
        useHTML: true,
        formatter: function (tooltip) {
          const point = this.point as unknown as { text?: string };
          const defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);

          if (!point.text) {
            return defaultTooltip;
          }

          const defaultTooltipArray = Array.isArray(defaultTooltip) ? defaultTooltip : [defaultTooltip];
          return defaultTooltipArray.concat([
            '<br />',
            '<span style="font-size:0.8em">Description:</span>',
            '<br />',
            `<div style="width:300px; white-space:normal; text-wrap:wrap">${point.text}</div>`
          ]);
        }
      },
      series: [
        {
          type: 'bar',
          name: 'Budget Requested',
          tooltip: {
            valuePrefix: '$'
          },
          data: data
        }
      ]
    };
  }, [governmentEntity?.latestUpload?.budgetaryRequests]);

  if (isLoading) return <Loading type="card" />;
  if (
    !governmentEntity ||
    !governmentEntity?.latestUpload?.budgetaryRequests?.length ||
    !(options.series?.[0] as Highcharts.SeriesBarOptions)?.data?.length
  )
    return null;

  const fiscalYear = governmentEntity.latestUpload.fiscalYear;
  return (
    <Card
      title={
        <span className="space-x-2">
          <LlmGeneratedIndicator icon="generate" /> Projected Budget Requests{fiscalYear ? ` (FY${fiscalYear})` : ''}
        </span>
      }
      titleRenderer={CardHeadingSmall}
      collapsible={true}
    >
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CardBody>
    </Card>
  );
};
