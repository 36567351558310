import React from 'react';

import { PageHeading } from '@/app/atoms/Typography/Typography';
import { Attachment } from '@/types/__generated__/GovlyApi';
import { cn } from '@/app/lib/cn';

type LogoOrNameProps = {
  name: string;
  logo?: Pick<Attachment, 'svg' | 'variantLink' | 'link'>;
  both?: boolean;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
};

export const LogoOrName = ({ logo, name, both = true, imgProps }: LogoOrNameProps) => {
  return (
    <div className="flex items-center gap-2">
      {logo && (
        <h1>
          <img
            className={cn('m-0 max-h-16 w-auto object-contain', imgProps?.className)}
            src={
              logo?.svg ? `data:image/svg+xml;utf8,${encodeURIComponent(logo?.svg)}` : logo?.variantLink || logo?.link
            }
            alt={`Logo for ${name}`}
            {...imgProps}
          />
        </h1>
      )}
      {(!logo || both) && <PageHeading>{name}</PageHeading>}
    </div>
  );
};
