import React, { useEffect, useMemo } from 'react';
import { Button, Menu, Popover } from '@blueprintjs/core';
import { CSVLink } from 'react-csv';
import { snakeCase } from 'lodash-es';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';
import { match } from 'ts-pattern';

import { ErrorBoundary } from '@/app/atoms/ErrorBoundary/ErrorBoundary';
import { Card, CardBody } from '@/app/atoms/Card/Card';
import { Loading } from '@/app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from '@/app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useLazyOppAggregationQuery } from '@/api/oppsApi';
import { CardError } from '@/app/atoms/ErrorFallback/CardError';

import { extractSeriesData } from '../../OppSearchAnalytics/useOppSearchAnalyticsPostedAtStore';

type AggType = 'postedAtAgg' | 'modifiedAtAgg' | 'respondByAgg';
type SliceType = 'total' | 'setAsidesAgg';

type Agg = {
  buckets: AggWithSubAggsBucket[];
  seriesData: Highcharts.SeriesOptionsType[];
  csv: string[];
};

type AggWithSubAggsBucket = AggBucket & {
  setAsidesAgg?: SubAgg;
};

export type AggWithSubAggs = {
  buckets: AggWithSubAggsBucket[];
};

type AggBucket = {
  key: string;
  keyAsString: string;
  docCount: number;
};

type SubAgg = {
  buckets: AggBucket[];
};

type AggregationQueryResponse = {
  aggs: {
    postedAtAgg?: Agg;
    modifiedAtAgg?: Agg;
    respondByAgg?: Agg;
    upcomingExpirationAgg?: Agg;
  };
};

const TITLE = 'Posted Over Time';

const GovernmentEntityOppsChart = ({ id, entityName }: { id: string; entityName: string }) => {
  const sliceType: SliceType = 'total';

  const sliceLabel = 'View';
  const dateRangeParam = 'posted_at';

  const aggType = match<string, AggType>(dateRangeParam)
    .with('modified_at', () => 'modifiedAtAgg')
    .with('respond_by', () => 'respondByAgg')
    .otherwise(() => 'postedAtAgg');

  const [getOppsAggregation, { data = {}, isLoading, isFetching, isError, isUninitialized }] =
    useLazyOppAggregationQuery();

  useEffect(() => {
    getOppsAggregation(
      {
        buyerIds: [id],
        aggs: [snakeCase(aggType)],
        aggSlices: sliceType === 'total' ? [] : [snakeCase(sliceType)]
      },
      true /* preferCache */
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOppsAggregation, aggType, sliceType]);

  const agg = (data as AggregationQueryResponse).aggs?.[aggType];
  const { seriesData, csv } = extractSeriesData(agg, sliceType);

  const options = useMemo<Highcharts.Options>(() => {
    return {
      series: seriesData,
      title: {
        text: undefined
      },
      legend: {
        enabled: sliceType !== 'total'
      },
      chart: {
        zooming: {
          singleTouch: true,
          type: 'xy'
        }
      },
      tooltip: {
        shared: false
      },
      time: {
        useUTC: true
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: undefined
        }
      },
      yAxis: {
        title: {
          text: undefined
        }
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          marker: {
            enabled: false
          }
        }
      }
    };
  }, [seriesData, sliceType]);

  if (isLoading || isFetching || isUninitialized) {
    return <Loading />;
  }

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  return (
    <>
      <Card
        title={TITLE}
        rightElement={
          <div className="flex items-center gap-2">
            {csv.length ? (
              <CSVLink
                data={csv}
                filename={`govly-posted-at-${entityName}-${new Date().toISOString().split('T')[0]}-results.csv`}
                className="text-gray-400 no-underline"
              >
                <Button outlined icon="download">
                  Download Data
                </Button>
              </CSVLink>
            ) : undefined}

            <Popover
              placement="bottom-start"
              minimal
              modifiers={{ offset: { enabled: true } }}
              content={
                <Menu>
                  {/* {SLICES.map(({ key, label }) => (
                    <MenuItem
                      key={String(key)}
                      active={sliceType === key}
                      onClick={() => setSliceType(key)}
                      text={label}
                    />
                  ))} */}
                </Menu>
              }
            >
              <Button rightIcon="chevron-down">{sliceLabel ?? 'View'}</Button>
            </Popover>
          </div>
        }
      >
        <CardBody>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </CardBody>
      </Card>
    </>
  );
};

const WithErrorBoundary = (props: React.ComponentProps<typeof GovernmentEntityOppsChart>) => (
  <ErrorBoundary action="GovernmentEntityOppsChart" fallback={<CardError title={TITLE} />}>
    <GovernmentEntityOppsChart {...props} />
  </ErrorBoundary>
);

export { WithErrorBoundary as GovernmentEntityOppsChart };
