import React from 'react';
import { Card as BpCard, NonIdealState } from '@blueprintjs/core';
import { Link } from 'react-router';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { defaultState } from '@/app/hooks/search/useOppSearchCache';

interface StatCardProps {
  value: number;
  label: string;
  to: string;
}

const StatCard = ({ value, label, to }: StatCardProps) => (
  <Link to={to}>
    <BpCard interactive className="h-full hover:bg-gray-50">
      <div className="p-4">
        <p className="text-xl font-bold text-center">{value}</p>
        <p className="text-sm text-center">{label}</p>
      </div>
    </BpCard>
  </Link>
);

export const RecentStats = ({ id }: { id: string }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity?.recentOppStats || Object.values(governmentEntity.recentOppStats).every(value => value === 0))
    return (
      <Card>
        <NonIdealState className="py-8" icon="array-numeric" title="No Recent Opps Available" />
      </Card>
    );

  const createSearchParams = (recordType: string[]) => {
    const isForecast = recordType.includes('Forecast');

    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    return {
      ...defaultState.filters,
      buyerIds: [id],
      recordType,
      dateRange: [startDate, endDate] as [Date, Date],
      dateRangeParam: 'respond_by',
      forecastStatus: isForecast ? 'forecasted' : undefined
    };
  };

  const stats = [
    {
      value: governmentEntity.recentOppStats.totalLastWeek,
      label: 'Recent Opportunities',
      to: `/opportunities?filters=${encodeURIComponent(JSON.stringify(createSearchParams(['Solicitation'])))}`
    },
    {
      value: governmentEntity.recentOppStats.forecastedLastWeek,
      label: 'Forecasted Opportunities',
      to: `/opportunities?filters=${encodeURIComponent(JSON.stringify(createSearchParams(['Forecast'])))}`
    },
    {
      value: governmentEntity.recentOppStats.predictedLastWeek,
      label: 'Govly Predicted Opportunities',
      to: `/opportunities?filters=${encodeURIComponent(JSON.stringify(createSearchParams(['Prediction'])))}`
    }
  ];

  return (
    <Card title={<span>Recent Stats</span>} titleRenderer={CardHeadingSmall} collapsible={true}>
      <CardBody data-test="government-entity-recent-stats">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          {stats.map(stat => (
            <StatCard key={stat.label} {...stat} />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};
