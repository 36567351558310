import React from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';
import { useNavigate } from 'react-router';

import { Vendor } from '@/types/__generated__/GovlyApi';

type VendorTagProps = {
  vendor: Vendor;
  intent: 'success' | 'primary';
  tooltip?: string;
};

export const VendorTag = ({ vendor, intent, tooltip }: VendorTagProps) => {
  const navigate = useNavigate();

  const tag = (
    <Tag
      minimal
      intent={intent}
      interactive={!!vendor.slug && (!!vendor.primaryWebsite || !!vendor.description)}
      onClick={() => navigate(`/vendors/${vendor.slug}`)}
    >
      {typeof vendor === 'string' ? vendor : vendor.name}
    </Tag>
  );

  if (tooltip) {
    return <Tooltip content={tooltip}>{tag}</Tooltip>;
  } else {
    return tag;
  }
};
