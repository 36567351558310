import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';

import { Card, CardBody } from '@/app/atoms/Card/Card';
import { useGetGovernmentEntityQuery } from '@/api/governmentEntitiesApi';
import { Loading } from '@/app/atoms/Loading/Loading';
import { CardHeadingSmall } from '@/app/atoms/Typography/Typography';
import { LlmGeneratedIndicator } from '@/app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';

type ChartView = 'top' | 'trending';

interface TrendingData {
  name: string;
  type: 'line';
  data: [number, number][];
}

interface TrendingDataMap {
  [key: string]: TrendingData;
}

export const TopRecipientsChart = ({ id, view = 'top' }: { id: string; view?: ChartView }) => {
  const { data: governmentEntity, isLoading } = useGetGovernmentEntityQuery({ id: id ?? '' }, { skip: !id });

  const options: Highcharts.Options = useMemo(() => {
    if (!governmentEntity?.topRecipients || !governmentEntity.trendingRecipients) return {} as Highcharts.Options;

    let data;
    if (view === 'top') {
      data = governmentEntity.topRecipients
        .map(recipient => ({
          name: recipient.name,
          y: recipient.total_amount,
          text: `Total award amount: $${recipient.total_amount.toLocaleString()}`
        }))
        .sort((a, b) => (b.y || 0) - (a.y || 0));

      return {
        plotOptions: {
          bar: {
            colorByPoint: true
          },
          column: {
            colorByPoint: true
          }
        },
        title: {
          text: undefined
        },
        chart: {
          type: 'bar',
          height: 350
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: undefined
          }
        },
        lang: {
          numericSymbols: ['Th', 'M', 'B', 'T']
        },
        tooltip: {
          valuePrefix: '$',
          useHTML: true,
          formatter: function (tooltip) {
            const point = this.point as unknown as { text?: string };
            const defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);

            if (!point.text) {
              return defaultTooltip;
            }

            const defaultTooltipArray = Array.isArray(defaultTooltip) ? defaultTooltip : [defaultTooltip];
            return defaultTooltipArray.concat([
              '<br />',
              '<span style="font-size:0.8em">Description:</span>',
              '<br />',
              `<div style="width:300px; white-space:normal; text-wrap:wrap">${point.text}</div>`
            ]);
          }
        },
        series: [
          {
            type: 'bar',
            name: 'Award Amount',
            tooltip: {
              valuePrefix: '$'
            },
            data: data
          }
        ]
      };
    } else {
      // Trending view
      data = governmentEntity.trendingRecipients?.reduce<TrendingDataMap>((acc, recipient) => {
        if (!acc[recipient.name]) {
          acc[recipient.name] = {
            name: recipient.name,
            type: 'line',
            data: []
          };
        }
        acc[recipient.name].data.push([recipient.fiscal_year, recipient.year_amount]);
        return acc;
      }, {});

      return {
        chart: {
          type: 'line',
          height: 350
        },
        series: Object.values(data)
      };
    }
  }, [governmentEntity, view]);

  if (isLoading) return <Loading type="card" />;
  if (!governmentEntity || !(options.series?.[0] as Highcharts.SeriesBarOptions)?.data?.length) return null;

  const fiscalYear = governmentEntity.latestUpload?.fiscalYear;
  return (
    <Card
      title={
        <span className="space-x-2">
          <LlmGeneratedIndicator icon="generate" />
          {view === 'top' ? 'Top Recipients' : 'Trending Recipients'}
          {fiscalYear ? ` (FY${fiscalYear})` : ''}
        </span>
      }
      titleRenderer={CardHeadingSmall}
      collapsible={true}
    >
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CardBody>
    </Card>
  );
};
