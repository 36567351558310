import React, { useMemo } from 'react';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';

import { AwardShow } from '@/types/__generated__/GovlyApi';

type Props = { award: AwardShow };
export type { Props as AwardDetailsAmountChartProps };

export const AwardDetailsAmountChart = ({ award }: Props) => {
  const [total, potential, obligated, outlayed] = [
    award.awardAmount,
    award.ceilingAmount,
    award.obligatedAmount,
    award.paidAmount
  ].map(val => (val ? parseFloat(val) : 0));

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        type: 'bar',
        height: 250,
        zooming: {
          singleTouch: true,
          type: 'y'
        }
      },
      title: { text: 'Award Amounts', align: 'left' },
      xAxis: {
        labels: { enabled: false },
        categories: ['Amounts']
      },
      yAxis: { title: { text: null } },
      plotOptions: { bar: { groupPadding: 0.6, pointWidth: 50 } },
      tooltip: { shared: false },
      series: [
        { name: 'Outlayed', type: 'bar' as const, data: [outlayed], pointWidth: 50 },
        { name: 'Obligated', type: 'bar' as const, data: [obligated], pointWidth: 54 },
        { name: 'Current Total', type: 'bar' as const, data: [total], pointWidth: 58 },
        { name: 'Potential Total', type: 'bar' as const, data: [potential], pointWidth: 62 }
      ].reverse()
    }),
    [outlayed, obligated, total, potential]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
