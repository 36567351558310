import { create } from 'zustand';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { match } from 'ts-pattern';
import { partition, startCase } from 'lodash-es';

import { DateRangeParam, OppSearchState } from '@/app/hooks/search/useOppSearchCache';
import { notNullish } from '@/app/lib/notNullish';
import { createSelectors } from '@/app/lib/createSelectors';

export type SliceType = 'total' | 'feedAgg' | 'noticeTypeAgg' | 'setAsidesAgg';

type AggBucket = {
  key: string;
  keyAsString: string;
  docCount: number;
};

type SubAgg = {
  buckets: AggBucket[];
};

type AggWithSubAggsBucket = AggBucket & {
  feedAgg?: SubAgg;
  noticeTypeAgg?: SubAgg;
  setAsidesAgg?: SubAgg;
};

export type AggWithSubAggs = {
  buckets: AggWithSubAggsBucket[];
};

export const SLICES: Array<{ key: SliceType; label: string }> = [
  { key: 'total', label: 'Total' },
  { key: 'feedAgg', label: 'Feeds' },
  { key: 'noticeTypeAgg', label: 'Notice Types' },
  { key: 'setAsidesAgg', label: 'Set Asides' }
];

type Store = {
  sliceType: SliceType;
  isDrawerOpen: boolean;
  lastClickedPoint: Highcharts.Point | undefined;
};

export { useStore as useOppSearchAnalyticsPostedAtStore };
const useStore = createSelectors(
  create<Store>(() => {
    return {
      sliceType: 'total',
      lastClickedPoint: undefined,
      isDrawerOpen: false
    };
  })
);

export const setSliceType = (sliceType: Store['sliceType']) => useStore.setState({ sliceType });
export const setIsDrawerOpen = (isDrawerOpen: Store['isDrawerOpen']) => useStore.setState({ isDrawerOpen });

export const selectedSeriesSelector = (state: Store) => {
  return [state.lastClickedPoint?.series?.name].filter((s): s is string => Boolean(s));
};

const getUTCDayRange = (dateOrNumber: Date | number): [Date, Date] => {
  const date = new Date(dateOrNumber);
  const utcMonth = date.getUTCMonth();
  const utcYear = date.getUTCFullYear();
  const utcDay = date.getUTCDate();
  const utcStart = new Date(Date.UTC(utcYear, utcMonth, utcDay));
  const utcEnd = new Date(Date.UTC(utcYear, utcMonth, utcDay, 23, 59, 59));
  return [utcStart, utcEnd];
};

export const getDrilldownFilters = ({
  dateRangeParam,
  setAsideNamesToCodes,
  point,
  sliceType,
  selectedSeries
}: {
  dateRangeParam: DateRangeParam;
  setAsideNamesToCodes: Map<string, string>;
  point?: Highcharts.Point;
  sliceType: SliceType;
  selectedSeries: string[];
}) => {
  if (!point) return {};

  const [utcStart, utcEnd] = getUTCDayRange(point.x);
  const dateFilter: Partial<OppSearchState['filters']> = { dateRangeParam, dateRange: [utcStart, utcEnd] };

  const sliceFilter = match<SliceType, Partial<OppSearchState['filters']>>(sliceType)
    .with('feedAgg', () => ({
      feeds: selectedSeries.map(name => name).filter(notNullish)
    }))
    .with('noticeTypeAgg', () => ({
      noticeType: selectedSeries.map(name => name.toLowerCase().replace(/ /g, '_'))
    }))
    .with('setAsidesAgg', () => {
      const [any, none] = partition(selectedSeries, name => name !== 'None');
      return {
        setAside: any.map(name => setAsideNamesToCodes.get(name)).filter(notNullish),
        setAsideNone: none.length > 0 ? [...setAsideNamesToCodes.values()] : []
      };
    })
    .otherwise(() => ({}));

  return { ...sliceFilter, ...dateFilter };
};

export const sliceLabelSelector = (store: Store) => {
  const { sliceType } = store;
  return SLICES.find(slice => slice.key && slice.key === sliceType)?.label;
};

export const extractSeriesData = (
  agg: AggWithSubAggs | undefined,
  sliceType: SliceType
): { seriesData: Highcharts.SeriesLineOptions[]; csv: string } => {
  if (!agg) {
    return { seriesData: [{ type: 'line', name: 'Total', data: [] }], csv: '' };
  }

  let seriesData: Highcharts.SeriesLineOptions[] = [];
  let csv = '';

  if (sliceType !== 'total') {
    csv = 'Date,Count,Slice';
    const aggMap: Record<string, [string, number][]> = {};

    agg.buckets.forEach(bucket => {
      const dateKey = bucket.key;
      const csvDateKey = bucket.keyAsString;
      const subAgg = bucket[sliceType];

      subAgg?.buckets.forEach(({ key, docCount }) => {
        aggMap[key] = [...(aggMap[key] || []), [dateKey, docCount]];
        csv += `\n${csvDateKey},${docCount},${key}`;
      });
    });
    seriesData = Object.entries(aggMap).map(([name, data]) => ({ type: 'line', name: startCase(name), data }));
  } else {
    csv = 'Date,Count';

    const mappedData = agg.buckets.map(({ key, docCount }) => {
      const point: [string, number] = [key, docCount];
      csv += `\n${point.join(',')}`;
      return point;
    });
    seriesData = [{ type: 'line', name: 'Total', data: mappedData }];
  }

  return { seriesData, csv };
};
