import { GetApiSliceRootState } from '@/app/lib/typeUtils';
import { oppWorkspacesApi } from '@/api/oppWorkspacesApi';
import { WorklistViewFilters } from '@/app/organisms/WorklistWorkspaces/types';
import { store } from '@/app/store';
import { SearchQueryMeta, WorkspaceColumnValue, WorkspaceSearch } from '@/types/__generated__/GovlyApi';

import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({
  addTagTypes: ['Worklist', 'WorklistSearch', 'Workspace', 'Opp', 'WorkspaceColumnValue', 'WorkspaceColumnValues']
});

type Search = {
  params: {
    filter: 'active' | 'inactive';
    page?: number;
    per?: number;
  } & Omit<WorklistViewFilters, 'status'>;
  result: {
    meta: SearchQueryMeta;
    results: WorkspaceSearch[];
  };
};
export type { Search as GetWorklistSearch };

export type GetWorklistColumnValues = {
  params: { workspaceIds: string[] };
  result: WorkspaceColumnValue[];
};

export const worklistApi = api.injectEndpoints({
  endpoints: build => ({
    worklistSearch: build.query<Search['result'], Search['params']>({
      query: params => ({ url: '/v2/worklist/search', params }),
      providesTags: result => [
        ...(result
          ? [
              ...result.results.map(({ id }) => ({ type: 'Workspace' as const, id })),
              ...result.results.map(({ workableId }) => ({ type: 'Opp' as const, id: workableId }))
            ]
          : []),
        'WorklistSearch'
      ]
    }),
    getWorklistColumnValues: build.query<GetWorklistColumnValues['result'], GetWorklistColumnValues['params']>({
      query: params => ({ url: '/v2/worklist/workspace_column_values', params }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: columnValues } = await queryFulfilled;
          const groupedByWorkspace = columnValues.reduce(
            (acc, cv) => {
              acc[cv.workspaceId] = [...(acc[cv.workspaceId] || []), cv];
              return acc;
            },
            {} as Record<string, WorkspaceColumnValue[]>
          );

          Object.entries(groupedByWorkspace).forEach(([workspaceId, columnValues]) => {
            dispatch(
              oppWorkspacesApi.util.upsertQueryData('getOppWorkspaceColumnValues', { id: workspaceId }, columnValues)
            );
          });
        } catch {}
      },
      providesTags: result => [
        ...(result ? [...result.map(({ id }) => ({ type: 'WorkspaceColumnValue' as const, id }))] : []),
        'WorkspaceColumnValues'
      ]
    }),

    refreshWorklist: build.mutation<void, void>({
      query: () => ({ url: '/v2/worklist/refresh', method: 'POST' })
    }),
    exportWorklist: build.mutation<void, { columns: { id: string; header: string }[] } & Search['params']>({
      query: params => ({ url: '/v2/worklist/export', params, method: 'POST', body: params })
    })
  })
});

export const {
  useGetWorklistColumnValuesQuery,
  useWorklistSearchQuery,
  useRefreshWorklistMutation,
  useExportWorklistMutation
} = worklistApi;

export const getCachedWorkspaceSearchIds = (params: Search['params']) => {
  const workspaceSearchIds = worklistApi.endpoints.worklistSearch.select(params)(
    store.getState() as GetApiSliceRootState<typeof worklistApi>
  );
  return workspaceSearchIds.data?.results.map(r => r.id) ?? [];
};
