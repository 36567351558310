import React, { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { Button, Tooltip } from '@blueprintjs/core';
import { Link } from 'react-router';

import { useGetAwardQuery, useAwardSearchMutation, AWARD_SEARCH_CACHE_KEY } from '@/api/awardsApiV2';
import { Loading } from '@/app/atoms/Loading/Loading';
import { Main } from '@/app/atoms/Main/Main';
import { NotAuthorized } from '@/app/molecules/NotAuthorized/NotAuthorized';
import { DRAWER_TYPES, DrawerType } from '@/app/organisms/AppDrawer/constants';
import { useAppDrawerSubNavTab } from '@/app/organisms/AppDrawer/useAppDrawerSubNavTab';
import { useAppDrawerStore } from '@/app/organisms/AppDrawer/useAppDrawerStore';
import { AwardDetailsOverviewContent } from '@/app/organisms/AwardDetailsOverviewContent/AwardDetailsOverviewContentV2';
import { useSearchDrawerNextPrevButtons } from '@/app/organisms/SearchDrawerNextPrevButtons/useSearchDrawerNextPrevButtonsV2';

export const showAwardDrawer = ({ drawerType, drawerId }: { drawerType: DrawerType; drawerId: string }) =>
  ['/awards/results-v2', '/awards-v2'].includes(window.location.pathname) && drawerType === 'awards-v2' && drawerId;

export const AwardDrawerBody = ({ id }: { id: string }) => {
  const tab = useAppDrawerSubNavTab('awards-v2');

  const [loadedId, setLoadedId] = useState<string | undefined>();
  const { data, isFetching, isError } = useGetAwardQuery({ uniqueKey: id, searchType: 'fed' });

  useEffect(() => {
    if (loadedId !== id && !isFetching) {
      setLoadedId(id);
    }
  }, [id, loadedId, isFetching]);

  useEffect(() => {
    useAppDrawerStore.setState({
      rightElement: <PrevNext id={id} />
    });

    return () => {
      useAppDrawerStore.setState({ rightElement: undefined });
    };
  }, [id]);

  if (loadedId !== id) return <Loading type="detail-layout" />;

  if (isError) {
    return <NotAuthorized />;
  }

  return (
    <Main className="max-w-full bg-gray-50">
      {match(tab)
        .with('Overview', () => data && <AwardDetailsOverviewContent uniqueKey={id} />)
        .otherwise(() => null)}
    </Main>
  );
};

const PrevNext = ({ id }: { id: string }) => {
  const { nextIdLink, prevIdLink, onClickNext, onClickPrev, hasNextPrev } = useSearchDrawerNextPrevButtons({
    id,
    resource: DRAWER_TYPES.awards,
    cacheKey: AWARD_SEARCH_CACHE_KEY,
    mutation: useAwardSearchMutation
  });

  if (!hasNextPrev) return null;

  return (
    <div className="flex justify-between gap-x-2">
      <Tooltip content="Previous Award" placement="bottom">
        {prevIdLink ? (
          <Link to={prevIdLink} className="no-underline">
            <Button icon="arrow-up" aria-label="Prev" />
          </Link>
        ) : (
          <Button
            {...(onClickPrev ? { onClick: onClickPrev } : { disabled: true })}
            icon="arrow-up"
            aria-label="Prev"
          />
        )}
      </Tooltip>

      <Tooltip content="Next Award" placement="bottom">
        {nextIdLink ? (
          <Link to={nextIdLink} className="no-underline">
            <Button icon="arrow-down" aria-label="Next" />
          </Link>
        ) : (
          <Button
            icon="arrow-down"
            aria-label="Next"
            {...(onClickNext ? { onClick: onClickNext } : { disabled: true })}
          />
        )}
      </Tooltip>
    </div>
  );
};
